import React from "react";
import {Row,Col,Layout, Typography, Image} from "antd";
import { setAppTitle } from "../../utils/helpers/title";
import { filter, groupBy,isEmpty } from 'lodash';
import {SectionLayout} from "../../layouts/sections/section";
import { Paper } from "@mui/material";

import {database} from "../../firebase/config";
import { onChildAdded, ref, onValue, off} from "firebase/database";
import userHelper from "../../utils/helpers/userHelper";
import formatHelper from "../../utils/helpers/formatHelper";
import TableSimpleCustom from "../../layouts/tables/table_simple_custom";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import dataHelper from "../../utils/helpers/dataHelper";

var columns = [
  {
    title: "PRENOM",
    key: "first_name",
    dataIndex:'first_name',
    fixed:'left',
    width:100,
  },
  {
    title: "NOM",
    key: "last_name",
    dataIndex:'last_name',
    fixed: null,
    width:100,
  },
  {
    title: "SEXE",
    key: "gender",
    dataIndex:'gender',
    fixed: null,
    width:70,
  },
  {
    title: "PHONE",
    key: "phone",
    dataIndex:'phone',
    fixed: null,
    width:100,
  },
  {
    title: "EMAIL",
    key: "email",
    dataIndex:'email',
    fixed: null,
    width:150,
  },
  
  {
    title: "NOMBRE - FICHE",
    key: "nbre",
    dataIndex:'nbre',
    fixed: "right",
    width:70,
  }
  
];


export function AnalyticsPage() {
const queryClient = useQueryClient();
const userAdmin = userHelper.getData();
const current = userHelper.getSurveyData();
const dispatch = useDispatch();
const peoples = useSelector(state=>state.people.all);
const users = useSelector(state=>state.user.all);
const researchs = useSelector(state=>state.research.all);
const provinces = useSelector(state=>state.province.all);

React.useEffect(()=>{
  const dataRef = ref(database, 'surveys/survey_'+current.id);
    onChildAdded(dataRef, async(snapshot)=> {
      //mettez ici vos fonctions qui permettent de charger les données...
      await dataHelper.initData(dispatch);
    });
  return ()=> {off(dataRef);}
},[]);


setAppTitle("Analytics");

  return (
    <>
     <Layout.Content>
       <Layout.Content style={{padding:"8px"}}>

        <Row gutter={[10,15]} align={"middle"} justify={"center"} wrap={true} style={{marginBlockEnd:"5px"}}>

          <Col flex={"auto"} style={{minHeight:"80px"}}>
            <Paper elevation={1} className="paper-total">
              <p className="paper-total-title">TOTAL</p>
              <p className="paper-total-value">
                {filter(peoples,{"investigation_id":current.id})?.length}
              </p>
            </Paper>
          </Col>

          <Col flex={"auto"} style={{minHeight:"80px"}}>
            <Paper elevation={1} style={{minHeight:"70px"}}>
              <Row style={{alignItems:"center",columnGap:"10px"}}>
                <Col><Typography.Title level={5} style={{margin:"0px",paddingInline:"5px"}}>Sexe</Typography.Title></Col>
              </Row>
              <Row align={"middle"} justify={"center"} style={{height:"60px"}}>
                <Col><Image preview={false} width={50} height={50} src={require("../../assets/images/M.png")} /></Col>
                <Col>
                  <Typography.Title level={5}>
                    {groupBy(filter(peoples,{"investigation_id":current.id}),"gender")['M']?.length}
                  </Typography.Title>
                </Col>

                <Col><Image preview={false} width={50} height={50} src={require("../../assets/images/F.png")} /></Col>
                <Col>
                  <Typography.Title level={5}>
                    {groupBy(filter(peoples,{"investigation_id":current.id}),"gender")['F']?.length}
                  </Typography.Title>
                </Col>
              </Row>
            </Paper>
          </Col>

          <Col flex={1} style={{minHeight:"80px"}}>
            <Paper elevation={1} style={{minHeight:"80px",padding:"5px"}}>
              <Row style={{alignItems:"center",columnGap:"10px"}}>
                <Col><Typography.Title level={5}>Télécommunication</Typography.Title></Col>
              </Row>
              <Row align={"middle"} justify={"center"} wrap={true} gutter={[10,10]}>
                {
                  filter(researchs,{"investigation_id":current.id,"column_id":"phone"}).map((r,k)=>(
                    <React.Fragment key={k}>
                    {
                      r.fields.map((f,ky)=>(
                        <React.Fragment key={ky}>
                          <Col><Typography.Text level={5}>{f.name}</Typography.Text></Col>
                          <Col>
                            <h3 className="analytic-value">
                              {formatHelper.getAnalyticsByKey(peoples,r.column_id,r.type, r.rule,f.contents,f.name)}
                            </h3>
                          </Col>
                        </React.Fragment>
                      ))
                    }
                    </React.Fragment>
                  ))
                }
              </Row>
            </Paper>
          </Col>

          {/*
            <Col flex={1} style={{minHeight:"80px"}}>
            <Paper elevation={1} style={{minHeight:"80px", padding:"5px"}}>
                <Row style={{alignItems:"center",columnGap:"10px"}}>
                  <Col><Typography.Title level={5}>Ville</Typography.Title></Col>
                </Row>
                <Row align={"middle"} justify={"center"} wrap={true} gutter={[10,10]}>
                  {
                    filter(provinces,{"investigation_id":current.id}).map((p,k)=>(
                      <React.Fragment key={k}>
                        <Col><Typography.Text level={5}>{p.name}</Typography.Text></Col>
                        <Col>
                          <h3 className="analytic-value">
                          {formatHelper.getAnalyticsByVille(peoples,"province","string","eq",p.name)}
                          </h3>
                        </Col>
                      </React.Fragment>
                    ))
                  }
                </Row>
            </Paper>
          </Col>
                */}

        </Row>

        <SectionLayout />

        <Paper elevation={1} style={{padding:"5px"}}>
          <Row style={{alignItems:"center",columnGap:"10px", padding:"10px"}}>
            <Col><Typography.Title level={5}>ENQUETEURS</Typography.Title></Col>
            <Col><Typography.Title level={5}>{isEmpty(users) ? "0" : users.length}</Typography.Title></Col>
          </Row>
          <TableSimpleCustom list={formatHelper.getListForTableSimple(users,peoples)} columns={columns} />
        </Paper>

       </Layout.Content>
     </Layout.Content>
    </>
  );
}


/**
 * <Layout.Content>
       <Layout.Content style={{padding:"8px"}}>

        <Row gutter={[10,15]} align={"middle"} justify={"center"} wrap={true} style={{marginBlockEnd:"5px"}}>

          <Col flex={"auto"} style={{minHeight:"80px"}}>
            <Paper elevation={1} className="paper-total">
              <p className="paper-total-title">TOTAL</p>
              <p className="paper-total-value">
                {filter(peoples,{"investigation_id":current.id})?.length}
              </p>
            </Paper>
          </Col>

          <Col flex={"auto"} style={{minHeight:"80px"}}>
            <Paper elevation={1} style={{minHeight:"70px"}}>
              <Row style={{alignItems:"center",columnGap:"10px"}}>
                <Col><Typography.Title level={5} style={{margin:"0px",paddingInline:"5px"}}>Sexe</Typography.Title></Col>
              </Row>
              <Row align={"middle"} justify={"center"} style={{height:"60px"}}>
                <Col><Image preview={false} width={50} height={50} src={require("../../assets/images/M.png")} /></Col>
                <Col><Typography.Title level={5}>{isEmpty(cGenders['M']) ? "0" : cGenders['M'].length}</Typography.Title></Col>

                <Col><Image preview={false} width={50} height={50} src={require("../../assets/images/F.png")} /></Col>
                <Col><Typography.Title level={5}>{isEmpty(cGenders['F']) ? "0" : cGenders['F'].length}</Typography.Title></Col>
              </Row>
            </Paper>
          </Col>

          <Col flex={1} style={{minHeight:"80px"}}>
            <Paper elevation={1} style={{minHeight:"80px",padding:"5px"}}>
              <Row style={{alignItems:"center",columnGap:"10px"}}>
                <Col><Typography.Title level={5}>Télécommunication</Typography.Title></Col>
              </Row>
              <Row align={"middle"} justify={"center"} wrap={true} gutter={[10,10]}>
                {
                  filter(researchs,{"investigation_id":current.id,"column_id":"phone"}).map((r,k)=>(
                    <React.Fragment key={k}>
                    {
                      r.fields.map((f,ky)=>(
                        <React.Fragment key={ky}>
                          <Col><Typography.Text level={5}>{f.name}</Typography.Text></Col>
                          <Col>
                            <h3 className="analytic-value">
                              {formatHelper.getAnalyticsByKey(peoples,r.column_id,r.type, r.rule,f.contents,f.name)}
                            </h3>
                          </Col>
                        </React.Fragment>
                      ))
                    }
                    </React.Fragment>
                  ))
                }
              </Row>
            </Paper>
          </Col>

          <Col flex={1} style={{minHeight:"80px"}}>
            <Paper elevation={1} style={{minHeight:"80px", padding:"5px"}}>
                <Row style={{alignItems:"center",columnGap:"10px"}}>
                  <Col><Typography.Title level={5}>Ville</Typography.Title></Col>
                </Row>
                <Row align={"middle"} justify={"center"} wrap={true} gutter={[10,10]}>
                  {
                    filter(provinces,{"investigation_id":current.id}).map((p,k)=>(
                      <React.Fragment key={k}>
                        <Col><Typography.Text level={5}>{p.name}</Typography.Text></Col>
                        <Col>
                          <h3 className="analytic-value">
                          {formatHelper.getAnalyticsByVille(peoples,"province","string","eq",p.name)}
                          </h3>
                        </Col>
                      </React.Fragment>
                    ))
                  }
                </Row>
            </Paper>
          </Col>

        </Row>

        <SectionLayout />

        <Paper elevation={1} style={{padding:"5px"}}>
          <Row style={{alignItems:"center",columnGap:"10px", padding:"10px"}}>
            <Col><Typography.Title level={5}>ENQUETEURS</Typography.Title></Col>
            <Col><Typography.Title level={5}>{isEmpty(users) ? "0" : users.length}</Typography.Title></Col>
          </Row>
          <TableSimpleCustom list={formatHelper.getListForTableSimple(users,peoples)} columns={columns} />
        </Paper>

       </Layout.Content>
     </Layout.Content>
 */