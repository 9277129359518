import React from 'react'
import { Col,Layout,Row, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { filter, isEmpty } from 'lodash';
import { IagChartCirclePie, IagChartVertical } from '../charts';
import chartHelper from '../../utils/helpers/chartHelper';
import ApexBarCustom from '../charts/apexBar';
import { database } from '../../firebase/config';
import { off, onChildAdded, ref } from "firebase/database";
import CarrouselBoostrap from './carrouselBoostrap';
import { useQueryClient } from '@tanstack/react-query';
import userHelper from '../../utils/helpers/userHelper';
import { SkeletonBarHorizon, SkeletonImage, SkeletonPie } from '../loader';
const { Content } = Layout;

export default function App(
  {
    sigle,
    description,
    typeChart="pie",
    orientation="circle",
    items,
    isLoading,
    contents = [],
    component="",
    responses=[],
  }
) {
  //const queryClient = useQueryClient();
  //const responseQuizzes = queryClient.getQueryData(['reponses']) || [];
  //const listPeopleId = []
  const current = userHelper.getSurveyData();

  React.useEffect(()=>{
    const dataRef = ref(database, 'surveys/survey_'+current.id);
      onChildAdded(dataRef, (snapshot)=> {
      //loadingUtils.realTime(dispatch, current.id);
    });
    return ()=> {off(dataRef);}
  },[]);

  
  return (
    <Layout.Content style={{width:"100%",height:"100%"}}>
        <Content style={{margin:"2px 7px"}}>
          <Typography.Text>
            {description}
          </Typography.Text>
        </Content>
        <Content style={{width:"100%",height:"440px"}}>
          {((contents.length > 0 && contents.length <=2) && component !=="image") && (isLoading && <SkeletonPie />)}
          {((contents.length > 0 && contents.length <=2) && component !=="image") && !isLoading && 
            <IagChartCirclePie 
              title={""} 
              data={chartHelper.groupResponse(responses,items.id, "content")} 
              type="pie"
             />
          }

          {((contents.length >2 && contents.length <=5) && component !=="image") && (isLoading && <SkeletonPie />)}
          {((contents.length >2 && contents.length <=5) && component !=="image") && !isLoading && 
            <IagChartCirclePie 
              title={""+items.id} 
              data={chartHelper.groupResponse(responses,items.id, "content")} 
              type="donut"
            />
          }

          {(contents.length >5 && component !=="image") && (isLoading && <SkeletonBarHorizon />)}
          {(contents.length >5 && component !=="image") && !isLoading && 
            <ApexBarCustom 
              data={chartHelper.groupResponse(responses,items.id, "content")} 
            />
          }

          {(contents.length == 0 && component !=="image") && (isLoading && <SkeletonBarHorizon />)}
          {(contents.length ==0  && component !=="image") && !isLoading && 
          <IagChartVertical
            title={""+items.id} 
            data={chartHelper.groupResponse(responses,items.id, "content")} 
            xField="type"
            yField='value'
            serieField={"value"}
            orientation='vertical'
          />}

          {(contents.length == 0 && component ==="image") && (isLoading && <SkeletonImage />)}
          {(contents.length == 0 && component ==="image") && !isLoading && 
             
             <CarrouselBoostrap 
               pictures={chartHelper.filterResponseImage(responses)}
             />
          }
        </Content>
        <Content>
          <Row style={{margin:"2px 10px"}}>
              <Col flex={"none"}>
                <Typography.Title level={5}>{sigle}</Typography.Title>
              </Col>
              <Col flex={"auto"}></Col>
              <Col flex={"none"}>
               {/*<EyeOutlined style={{fontSize:"20px"}} />*/}
              </Col>
          </Row>
        </Content>
    </Layout.Content>
  )
}

App.propTypes = {
  sigle: PropTypes.string,
  description: PropTypes.string,
  typeChart:PropTypes.string,
  orientation:PropTypes.string,
  items:PropTypes.any,
}; 


/**
 * 
 *  <Content style={{width:"100%",height:"440px"}}>
          {contents.length <=2 && component !=="image" &&
          <IagChartCirclePie 
            title={""} 
            data={chartHelper.groupResponse(chartHelper.filterResponseByPeoples(responseQuizzes, listPeopleId,"people_id"),items.id, "content")} 
            type="pie"
          />}
           {contents.length >2 && contents.length <=5 && component !=="image" &&
          <IagChartCirclePie 
            title={""+items.id} 
            data={chartHelper.groupResponse(chartHelper.filterResponseByPeoples(responseQuizzes, listPeopleId,"people_id"),items.id, "content")} 
            type="donut"
          />}
          {contents.length >5 && component !=="image" &&
            <ApexBarCustom 
              data={chartHelper.groupResponse(chartHelper.filterResponseByPeoples(responseQuizzes, listPeopleId,"people_id"),items.id, "content")} 
            />
          }
          {isEmpty(contents) && component !=="image" &&
          <IagChartVertical
            title={""+items.id} 
            data={chartHelper.groupResponse(chartHelper.filterResponseByPeoples(responseQuizzes, listPeopleId,"people_id"),items.id, "content")} 
            xField="type"
            yField='value'
            serieField={"value"}
            orientation='vertical'
          />}
          {isEmpty(contents) && component ==="image" &&
             <CarrouselBoostrap 
               pictures={chartHelper.groupResponseImage(chartHelper.filterResponseByPeoples(responseQuizzes, listPeopleId,"people_id"),
               items.id, "photo")}
             />
          }
        </Content>
 * 
 * 
 */

/**
 * 
 * <Carousel effect='scrollx' autoplay style={{backgroundColor:"grey"}}>
              {chartHelper.groupResponseImage(chartHelper.filterResponseByPeoples(responseQuizzes, listPeopleId,"people_id"),items.id, "photo")
               .map((c,i)=>{
                return <div>
                  <Image
                    style={contentStyle}
                    src={c.photo}
                   />
                </div>
               })}
           </Carousel>
 * 
 * 
 */
