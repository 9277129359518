import { List, Spin, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';


export const MeLegend:React.FC<{loading?:boolean}>=({loading})=>{
    const legends = useSelector((state:RootState)=>state.dashboard.legends);


    const renderContent=(item:any,index:number)=>{

      return(
        <React.Fragment key={`content_${index}`}>
          <div className="row-sync" style={{alignItems:"center"}}>
            <div style={{display:"flex",alignItems:"start",alignContent:"center",columnGap:"2px"}}>
              <div style={{height:"10px",width:"10px",borderRadius:"10px",backgroundColor:item.color,marginBlock:"auto"}}></div>
              <div>{item.name}</div>
            </div>
            {(loading) && <div className="total secondary"><Spin spinning size="small" /></div>}
            {(!loading) && (
                <div className="total secondary" style={{color:item.color ?? ""}}>
                  {item.sum}
                </div>
                         )}
            </div>
        </React.Fragment>
      )
    }

    const renderItem = (item:any,index:number)=>{
      return(
        <React.Fragment key={`item_${index}`}>
          <Typography.Title level={4}>{item.title}</Typography.Title>
          <List dataSource={item.contents} locale={{emptyText:""}} renderItem={renderContent} />
        </React.Fragment>
      )
    }

    return(
        <>
        {!loading && 
           <List dataSource={legends} renderItem={renderItem} />
        }
        </>
    )
}