import React from 'react';
import { useSelector } from 'react-redux';
import { filter, isEmpty } from 'lodash';
import { Typography } from "antd";
import ReactMapGL, { NavigationControl, Marker, Popup } from "react-map-gl";
import { MAP_TOKEN } from '../../services/config';
import formatHelper from '../../utils/helpers/formatHelper';

export function MapboxLayout({ center }) {
  const [mapViewport, setMapViewport] = React.useState({
    height: "400px",
    width: "100%",
    longitude: parseFloat(center.longitude),
    latitude: parseFloat(center.longitude),
    zoom: 5
  });

  return (
    <ReactMapGL
      {...mapViewport}
      mapboxApiAccessToken={MAP_TOKEN}
      mapStyle={"mapbox://styles/mapbox/streets-v12"}
      onViewportChange={(nextViewport) => setMapViewport(nextViewport)}
    >
      <NavigationControl></NavigationControl>
      <Markers />
    </ReactMapGL>
  );
}

export function Markers(props) {

  const coordinates = useSelector((state) => state.dashboard.coordinates);

  const legendes = useSelector(state => state.legende.list);
  const treatments = [];
  const [selectedLocation, setSelectedLocation] = React.useState({});
  const handleClick = React.useCallback((item) => {
    if (!isEmpty(item)) {
      setSelectedLocation({
        ...item,
        latitude: parseFloat(!isEmpty(item.latitude) ? item.latitude : 0.00),
        longitude: parseFloat(!isEmpty(item.longitude) ? item.longitude : 0.00),
      })
    }
  }, [])

  return (
    <React.Fragment>
      {!isEmpty(selectedLocation) && (
        <CustomPopup marker={selectedLocation} closePopup={() => setSelectedLocation({})} />)}
      {
        coordinates.map((p, i) => (
          <React.Fragment>
            <Marker
              onClick={() => handleClick(p)}
              {...props.marker} longitude={parseFloat(!isEmpty(p.longitude) ? p.longitude : 0.0)}
              latitude={parseFloat(!isEmpty(p.latitude) ? p.latitude : 0.0)}
            />

          </React.Fragment>
        ))
      }
    </React.Fragment>
  );

}

const noopM = () => { };

Markers.defaultProps = {
  marker: true,
  onLoading: noopM,
  onResults: noopM,
  onResult: noopM,
  onError: noopM
};

export function InfoBulle(props) {
  const { people, legendes, treatments } = props;
  return (
    <React.Fragment>
      <div style={{ width: "350px" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ marginInlineEnd: '5px', fontWeight: "bold" }}>
            {/* {formatHelper.getInfoBulle(legendes, people, treatments)['title']} */}
          </div>
          <div>
            {/* {formatHelper.getInfoBulle(legendes, people, treatments)['value']} */}
          </div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ marginInlineEnd: '5px', fontWeight: "bold" }}>Ville</div>
          <div>{isEmpty(people.province) ? "" : people.province}</div>
        </div>

        <Typography.Text style={{ marginTop: "5px" }}>long: {people.longitude}, lat: {people.latitude}</Typography.Text>
      </div>
    </React.Fragment>
  );
}

const CustomPopup = ({ index, marker, closePopup, people = {}, legendes = [], treatments = [] }) => {
  return (
    <Popup
      latitude={marker.latitude ?? 0}
      longitude={marker.longitude ?? 0}
      onClose={closePopup}
      closeButton={true}
      closeOnClick={false}
      anchor='top'
    //offsetTop={-30}
    >
      <InfoBulle people={marker} legendes={legendes} treatments={treatments} />
    </Popup>
  )
};