import React , {useState, useRef} from "react";
import { LoaderWQuiz } from "../../../components/loader";
import { Layout } from "antd";
import Grid from '@mui/material/Grid';
import { Paper } from "@mui/material";
import { IagCardChart } from "../../../components/cards";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { isArray, isEmpty } from "lodash";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList,  FixedSizeGrid} from "react-window";
import { toJson } from "../../../utils/helpers/title";




export const MeQuiz:React.FC<{loading:boolean}>=({loading})=>{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const questions = useSelector((state:RootState)=>state.dashboard.questions);
    const statics = useSelector((state:RootState)=>state.dashboard.statics);

    return(
        <div style={{position:"relative",height:"100%"}}>
        {loading && <LoaderWQuiz absolute /> }
        {!loading && (


            <div>

              { windowSize.current[0] >= 1000 && <div className="grid-quiz">


                {questions.map((item:any,index:number)=>(
                    <Grid key={index} xs={12} sm={12} md={12} item>
                        <Paper style={{width:"100%",height:"100%"}}>
                            <Layout.Content style={{minHeight:"530px",width:"100%",padding:"3px"}}>
                            <IagCardChart 
                                component={item.component} 
                                sigle={item.sigle}
                                description={item.description}
                                contents={toJson(item.contents)}
                                items={item} isLoading={loading}
                                responses={item.responses}
                              />
                            </Layout.Content>
                        </Paper>
                    </Grid>
                ))}

                </div>}

                
              { windowSize.current[0] < 1000 && <FixedSizeList
              
              height={550*questions.length + 150}
              width={window.innerWidth * 0.73}
              itemCount={questions.length}
              itemSize={questions.length} 
              style={{display: "flex", width: "100%"}}
              >
              {({data, index, style}) => {
                let idx : number = index;
                return (                   
                  <div >
{                        <Paper style={{width:"100%",height:"100%"}}>
                        <Layout.Content  style={{minHeight:"550px",width:"100%",padding:"3px"}}>
                        <IagCardChart 
                            component={questions[idx].component} 
                            sigle={questions[idx].sigle}
                            description={questions[idx].description}
                            contents={toJson(questions[idx].contents)}
                            items={questions[idx]} isLoading={loading}
                            responses={questions[idx].responses}
                          />
                        </Layout.Content>
                    </Paper>}
                  </div>
                );
              }}
            </FixedSizeList>}


            </div>
        )
        }
         {/*
           filter(quizzes,{"investigation_id":current.id}).map((item,index)=>{
             return (
                 <Grid key={index} xs={12} sm={12} md={12} item>
                   <Paper style={{width:"100%",height:"100%"}}>
                     <Layout.Content style={{minHeight:"530px",width:"100%",padding:"3px"}}>
                       <IagCardChart sigle={item.sigle} description={item.description} items={item} isLoading={isFetchingReponse} />
                     </Layout.Content>
                   </Paper>
               </Grid>
             );
             })
        */}
        </div>
    )
}