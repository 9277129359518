import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProvinceData {
  profil: any;
  current: any;
  list: any[];
  all:any[];
}

const initialState: ProvinceData = {
  profil: {},
  current: {},
  list: [],
  all:[],
};

export const provinceSlice = createSlice({
  name: "province",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setListProvince: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setListAllProvince: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const {
  setProfil,
  setCurrent,
  setListProvince,
  setListAllProvince,
} = provinceSlice.actions;
export default provinceSlice.reducer;

