import React from 'react'
import PropTypes from "prop-types";
import ReactApexChart from 'react-apexcharts';
import { Layout } from 'antd';
import chartHelper from '../../utils/helpers/chartHelper';

export default function App({
    title="",
    data=[{}],
    type="pie",
  }) {
  const options = {
        chart: {
          width: "100%",
          height:"100%",
          type: type,
        },
        fill:{
          colors:['#54B4D3', '#E4A11B','#9FA6B2','#14A44D','#3B71CA','#DC4C64'],
        },
        legend: {
          position: 'bottom',
          fill:{
            colors:['#54B4D3', '#E4A11B','#9FA6B2','#14A44D','#3B71CA','#DC4C64'],
          }
        },
        colors:['#54B4D3', '#E4A11B','#9FA6B2','#14A44D','#3B71CA','#DC4C64'],
        labels: chartHelper.getLabels(data, "type"),
        responsive: [{
          breakpoint: 770,
          options: {
            chart: {
              width: "100%",
              height:"100%"
            },
            legend: {
              position: 'bottom',
              fill:{
                colors:['#54B4D3', '#E4A11B','#9FA6B2','#14A44D','#3B71CA','#DC4C64'],
              }
            }
          }
        }]
      };

 return <Layout.Content style={{height:"400px", width:"100%"}}>
    <ReactApexChart options={options} series={chartHelper.getSeries(data,"type")} type={type} width={"100%"} height={"100%"} />
 </Layout.Content>
}

App.propTypes = {
  title: PropTypes.string,
  data:PropTypes.array,
  type:PropTypes.string,
};  