import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {

  apiKey: "AIzaSyBwNfs5FkEpcHY2-gcEAZ3ho7wfgxwgdDk",

  authDomain: "icollect-5e1e6.firebaseapp.com",

  databaseURL: "https://icollect-5e1e6-default-rtdb.firebaseio.com",

  projectId: "icollect-5e1e6",

  storageBucket: "icollect-5e1e6.appspot.com",

  messagingSenderId: "84705801096",

  appId: "1:84705801096:web:f580046b56cebe8513c03d",

  measurementId: "G-0ZBS29TRMQ"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export{app,database};

