import React from 'react'
import PropTypes from "prop-types";
import ReactApexChart from 'react-apexcharts';
import { Layout } from 'antd';
import chartHelper from '../../utils/helpers/chartHelper';
//chartHelper.getLabels(data, "type")
export default function ApexBarCustom({
    title="",
    data=[{}],
    type="bar",
  }) {
   
  const  series = [{name: 'Value',data: chartHelper.getSeries(data, "type")}];
  const options = {
        chart: {
          width: "100%",
          height:"100%",
          type: type,
        },
        plotOptions:{
          bar:{
            borderRadius: 4,
            horizontal:true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: chartHelper.getLabels(data, "type"),
        }
      };

 return <Layout.Content style={{height:"400px", width:"100%"}}>
    <ReactApexChart options={options} series={series} type={type} width={"100%"} height={"100%"} />
 </Layout.Content>
}

ApexBarCustom.propTypes = {
  title: PropTypes.string,
  data:PropTypes.array,
  type:PropTypes.string,
};  