import React, { useRef, useState } from 'react';
import { Table,Button } from 'antd';
import { CheckCircleOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Space} from 'antd';
import Highlighter from 'react-highlight-words';
import { addEvent } from '../../utils/helpers/exportHelper';
import { SkeletonBarHorizon } from '../../components/loader';
import { useVT } from 'virtualizedtableforantd4';

export default function TableAntCustom({columns,list,loading=true}) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [ vt, set_components ] = useVT(() => ({ scroll: { y: 800 } }), []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onEditedProps = ()=>({
    render:(record)=>{
      return (
      <>
        <EditOutlined 
          onClick={()=>{
            //setOpen(true);
            
          }}
        />
      </>
      )
    }
  })

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 135,
            }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 110,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtre
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <React.Fragment>
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
          />
          {
            filtered && (
              <CheckCircleOutlined
                style={{
                  color: 'teal',
                  marginLeft:'8px'
                }}
               />
            )
          }
      </React.Fragment>
    ),
    onFilter: (value, record) =>
      record[dataIndex] !=null || record[dataIndex] != undefined ? record[dataIndex].toString().
      toLowerCase().includes(value.toLowerCase()) : false
    ,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText,'']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
     
    },
    onSelect: (record, selected, selectedRows) => {
      
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      
    },
  };

  return (
    <>
     <Table 
          columns={addEvent(columns, getColumnSearchProps,onEditedProps)}
          dataSource={list}
          rowKey="id"
          rowSelection={rowSelection}
          scroll={{x: "100%",y:600}}
          loading={loading}
          //scroll={{ y: 600 }} // It's important for using VT!!! DO NOT FORGET!!!
          components={vt}
          
      >
     </Table>
    </>
  );
}
