import React from "react";
import "./assets/styles/mapbox-gl-geocoder.css";
import "antd/dist/antd.css";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "./assets/scss/app.scss";
import 'mapbox-gl/dist/mapbox-gl.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Main from "./Main";
import { Provider } from "react-redux";
import store from "./redux/store";
// import { PersistGate } from 'redux-persist/integration/react';

//const socket = io.connect(config.SOCKET_URL);
const queryClient = new QueryClient();

function App() {
   return (
      <QueryClientProvider client={queryClient}>
         <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
               <Main />
            {/* </PersistGate> */}
         </Provider>
         <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
   );
}

export default App;
 
/**
 * 
 * 
 *  <Provider store={store}>
      <QueryClientProvider client={queryClient}>
         <Main />
      </QueryClientProvider>
    </Provider>
 * 
 * 
import config from "./services/config";
//import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
 * <Provider store={store}>
      <SocketProvider socket={socket}>
        <Main />
      </SocketProvider>
    </Provider>
 */
