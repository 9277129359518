import {DownOutlined,LogoutOutlined,UserOutlined} from "@ant-design/icons";
import { Avatar, Button, Divider, Modal, Popover } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import userHelper from "../../../utils/helpers/userHelper";
import propTypes from "prop-types";

export const ProfilContent = ({ setVisible }) => {
  const navigate = useNavigate();
 
  function onLoagout() {
    userHelper.setLogout();
    window.location.href = "/";
  }

  function handleLogout() {
    Modal.confirm({
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter?",
      cancelText: "Annuler",
      okText: "Se déconnecter",
      onOk: onLoagout,
    });

    if (typeof setVisible === "function") {
      setVisible(false);
    }
  }

  return (
    <div>
      <div style={{display:"flex",alignItems:"center"}}>
        <Button onClick={handleLogout} type="text" danger>
          <LogoutOutlined style={{ paddingRight: 5, alignItems:"center" }} /> Se déconnecter
        </Button>
      </div>
    </div>
  );
};

ProfilContent.propTypes = {
  setVisible: propTypes.func,
};

export function ProfilMenu() {
  const [visible, setVisible] = React.useState(false);
  const user = userHelper.getData();

  function handleOpen() {
    setVisible(true);
  }

  function handleClose() {
    setVisible(false);
  }
  
  return (
    <React.Fragment>
      <Popover
        content={<ProfilContent setVisible={setVisible} />}
        placement="bottomLeft"
        onOpenChange={handleClose}
        open={visible}
      >
        <button
          style={{ display: "flex", alignSelf: "baseline", backgroundColor:"#fff"}}
          shape="circle"
          onClick={handleOpen}
          type="text"
          className="yc-box yc-nav-btn"
        >
          <div style={{ display: "flex", alignItems: "center", backgroundColor:"#fff"}}>
            <Avatar
              size="medium"
              className="yc-nav-avatar"
              src={isEmpty(user.photo) ? null : user.photo}
              style={{display:"flex", alignItems:"center"}}
            >
              {isEmpty(user.photo) && (
                <UserOutlined style={{ color: "#000", fontSize:"15px" }} />
              )}
            </Avatar>
            <DownOutlined className="material-icons" style={{fontSize:"12px"}} />
          </div>
        </button>
      </Popover>
    </React.Fragment>
  );
}
