import React from "react";
import { LoaderMapBox } from "../../../components/loader";
import GeodecorLayout from "../../../layouts/geoloc/geodecor";
import * as geolib from 'geolib';
import { filter, isEmpty } from "lodash";
import chartHelper from "../../../utils/helpers/chartHelper";
import { GeolibInputCoordinates } from "geolib/es/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";


export const MeMap:React.FC<{loading:boolean}>=({loading})=>{

    const coordinates = useSelector((state:RootState)=>state.dashboard.coordinates);
    const [center,setCenter] = React.useState<any>();
    const [ isPending, startTransition ] = React.useTransition();

    React.useEffect(()=>{
        startTransition(()=>{
            setCenter(
                geolib.getCenter(coordinates)
            );
        })
    },[coordinates])

    return(
        <>
            <div className="carte-map" style={{position:"relative"}}>
                {(loading || isPending) && <LoaderMapBox absolute />}
                {(!loading && !isPending) && (
                    <GeodecorLayout center={center} />
                )}
                </div>
        </>
    )
}