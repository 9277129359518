import axios from "axios";
import { isEmpty } from "lodash";
import userHelper from "../utils/helpers/userHelper";
import config from "./config";

export const http = axios.create({
  baseURL: config.URL_API,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "crossDomain": true,
    "Accept": "*/*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization, X-Auth-Token",
  }
});

export const CancelToken = axios.CancelToken;

const setAuthHeader = (config: any) => {

  const token = isEmpty(userHelper.getToken()) ? " 261|JIsBcM6bqXRCwlTWTAlUn3e5WJ6SivuZIbSO6cml" : userHelper.getToken(); //api-prod
  //const token = isEmpty(userHelper.getToken()) ? "104|MRvgbL0W53yhtPX8rfqjiP0MuCXxdpxXk7HS8zCz" : userHelper.getToken(); //api-test
  //const token = isEmpty(userHelper.getToken()) ? "214|UIkzh23bB0B1NKNpDGI1JaTL7K4qAszyFTkLD5MK" : userHelper.getToken(); //local
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  } else {
    config.cancelToken = new CancelToken((cancel) => cancel("No token"));
  }
  return config;
};

http.interceptors.request.use(setAuthHeader);

export default http;
