import { isArray, isEmpty} from "lodash";

const appName = "WiiCollect";
const refKey = "iag-ref";

const setAppTitle = (value: string) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}

function toJson(element:any){
  let result:any[] = [];
  if(isArray(element)){
    return element;
  }
  try {
    if(!isEmpty(element)){
      result = JSON.parse(element);
    }
  } catch (error) {
    
  }
  return result;
}

function getNumberOnly(value:string){
  let result = "";
  if(isEmpty(value)){
    return "";
  }
  const numbers = [];
  for (let i = 0; i < 10; i++) {
    numbers.push(`${i}`);
  }
  for (let index = 0; index < value.length; index++) {
    const element = value[index];
    if( numbers.findIndex(p=>p==element) !== -1 ){
      result += element;
    }
  }
  return result;
}

export { appName, getUid, refKey, setAppTitle, toJson, getNumberOnly };
