import { Action, Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import surveyReducer from "./surveyReducer";
import sectionReducer from "./sectionReducer";
import reponseReducer from "./reponseReducer";
import quizReducer from "./quizReducer";
import provinceReducer from "./provinceReducer";
import peopleReducer from "./peopleReducer";
import treatmentReducer from "./treatmentReducer";
import researchReducer from "./researchReducer";
import legendeReducer from "./legendeReducer";
import dashboardReducer from "./dashboardReducer";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import localforage from 'localforage';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';


const persistConfig: PersistConfig<any, any, any, any> = {
  key: "root",
  storage: localforage
}

const reducers = combineReducers({
  user: userReducer,
  survey: surveyReducer,
  section: sectionReducer,
  reponse: reponseReducer,
  quiz: quizReducer,
  province: provinceReducer,
  people: peopleReducer,
  treatment: treatmentReducer,
  research: researchReducer,
  legende: legendeReducer,
  dashboard: dashboardReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store)
export default store;
