import { Layout, Result } from "antd";
import React from "react";

export function NotFoundScreen() {
  return (
    <Layout
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result
        status={"404"}
        title={"Le chemin n'est pas trouvé"}
        subTitle={"Veuillez vérifier votre abonnement"}
      />
    </Layout>
  );
}
