import * as React from 'react';
import PropTypes from "prop-types";
//import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup} from '@progress/kendo-react-excel-export';
import { Breadcrumb, Button, Card, Col,Layout,Row, Typography} from "antd";
import tableHelper from '../../utils/helpers/tableHelper';
import TableAntCustom from '../../layouts/tables/table_ant_custom';
import userHelper from '../../utils/helpers/userHelper';
import { useSelector } from 'react-redux';


export function ExportData({columns,loading=true}){
  const current = userHelper.getSurveyData();
  const treats = useSelector((state)=>state.treatment.list);
  const data = useSelector((state)=>state.treatment.data);
/*
  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
*/
  return (
    <>
       {/*<ExcelExport data={data} ref={_export}>
          {columns.map((d,k)=>{
              return <React.Fragment key={k}>
                {d.key !=="section" &&
                <ExcelExportColumn
                  field={d.key}
                  title={d.title}
                  width={d.width}
                />}
                {d.key ==="section" && 
                  <ExcelExportColumn
                  field={d.key}
                  title={d.title}
                  width={d.width}
                />
               
                }
              </React.Fragment>
          })}
        </ExcelExport>*/}
        <Layout>
          <Layout.Content className="wrapper-header">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>Survey</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Typography.Title level={5}>{current?.sigle}</Typography.Title>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div>
              {/*<Button onClick={excelExport}>Exporter en excel</Button>*/}
            </div>
          </Layout.Content>

          <Layout.Content className="container-explore">
           {<TableAntCustom loading={loading} columns={columns} list={data} />}
          </Layout.Content>
        </Layout>
    </>
  );
}

ExportData.propTypes = {
  title: PropTypes.string,
  columns : PropTypes.array,
  list:PropTypes.array,
};


/**
 * 
 * <ExcelExportColumnGroup title="SECTIONS" headerCellOptions={{textAlign: 'center'}}>
                  <ExcelExportColumn field="subject" title="Age"/>
                  </ExcelExportColumnGroup>
 * <ExcelExportColumn field="ProductID" title="Product ID" locked={true} width={50} />
          <ExcelExportColumn field="ProductName" title="Product Name" width={350} />
          <ExcelExportColumnGroup title="Availability" headerCellOptions={{textAlign: 'center'}}>
            <ExcelExportColumn field="UnitPrice" title="Price" 
              cellOptions={{format: '$#,##0.00'}} width={150} footerCellOptions={{wrap: true,textAlign: 'center'}}
              groupFooterCellOptions={{textAlign: 'right'}} />
            <ExcelExportColumn field="UnitsOnOrder" title="Units on Order" />
            <ExcelExportColumn field="UnitsInStock" title="Units in Stock" />
          </ExcelExportColumnGroup>
 */