import { Axios, AxiosResponse } from "axios";
import {http} from "./apiConfig";
export default abstract class Crud {

  abstract getAll(): any;

  abstract get(id: number | string): Promise<AxiosResponse>;

  abstract getByKey(query: string): Promise<AxiosResponse>;

  abstract store(data: object | any): Promise<AxiosResponse>;

  abstract update(
    id: number | string,
    data: object | any
  ): Promise<AxiosResponse>;

  abstract destroy(id: number | string): Promise<AxiosResponse>;

  abstract url: string;

  protected axios: Axios = http;
  //protected unsecureAxios: Axios = https;

  public getData(response: AxiosResponse<any, any>): [] | any[] {
    if (typeof response.data !== "object") {
      return [];
    }
    return response.data;
  }

}
