import { isEmpty, lowerCase } from "lodash";
import { setProfil } from "../../redux/userReducer";
import { setSurveyProfil,setCurrentSurvey } from "../../redux/surveyReducer";
import { userType } from "../../routes";

class UserHelper {
  private key = "iag_user";
  private tokenKey = "iag_token";

  private yesKey = "wi_yes";

  getToken(): string | null {
    let response = null;
    const item = window.localStorage.getItem(this.tokenKey);
    if (typeof item === "string") {
      response = item;
    }
    return response;
  }

  setToken(token: string) {
    window.localStorage.setItem(this.tokenKey, token);
  }

  getData(): any | null {
    let response: any = null;
    const item = window.localStorage.getItem(this.key);
    if (typeof item === "string") {
      window.localStorage.removeItem(this.tokenKey);
      response = JSON.parse(item);
    }
    return response;
  }

  setData(item: object) {
   
    window.localStorage.setItem(this.key, JSON.stringify(item));
  }

  getSurveyData(): any | null {
    let response: any = null;
    const item = window.localStorage.getItem(this.yesKey);
    if (typeof item === "string") {
      response = JSON.parse(item);
    }
    return response;
  }

  setSurveyData(item: object) {
    
    //window.localStorage.removeItem(this.yesKey);
    window.localStorage.setItem(this.yesKey, JSON.stringify(item));
  }

  setLogout(dispatch: any) {
    if (typeof dispatch === "function") {
      dispatch(setProfil({}));
      dispatch(setSurveyProfil({}))
    }
    window.localStorage.removeItem(this.key);
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.yesKey);
  }

  isConnected() {
    const user = this.getData();
    return user !== null;
  }

  isSimple(user:any) :boolean{
    return !isEmpty(user) && !isEmpty(user.role) && lowerCase(user.role) ==="simple";
  }
  
  isAdmin(user:any):boolean{
    return !isEmpty(user) && !isEmpty(user.role) && lowerCase(user.role) ==="admin";
  }

  isSuperAdmin(user:any):boolean{
    return !isEmpty(user) && !isEmpty(user.role) && lowerCase(user.role) ==="super admin";
  }

  isVisible(
    values: userType[],
    user: any,
    subscribeable: boolean,
    subscriptionValid: boolean
  ) {
    let response = false;
    if (
      values.findIndex((p) => p === "simple") !== -1 &&
      this.isSimple(user)
    ) {
      response = true;
    } else if (
      values.findIndex((p) => p === "admin") !== -1 &&
      this.isAdmin(user)
    ) {
      response = true;
    } else if (
      values.findIndex((p) => lowerCase(p) === "super admin") !== -1 &&
      this.isSuperAdmin(user)
    ) {
      response = true;
    }
    if (subscribeable && !subscriptionValid) {
      response = false;
    }
    return response;
  }

  visibilityFilter(pathname:string):boolean{
    return pathname==="/home" || pathname==="/home/"  ? true : false;
  }

  visibilityFilterPath(pathname:string):boolean{
    return pathname==="/admin" || pathname==="/admin/" || pathname==="/admin/dashboard" ? true : false;
  }
}

export default new UserHelper();
