import { isEmpty } from "lodash";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Navbar } from "../../components/navigation/home/navbar";
import { routes } from "../../routes";
import { setAppTitle } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { NotFoundScreen } from "../404";

export function HomeLayout() {

  const routerLocation = useLocation();
  const userAdmin = userHelper.getData();
 
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);
  
  if (isEmpty(userAdmin)) {
    return (
      <Navigate
        to="/login"
        replace
        state={{ message: "Vous devez vous connecter avant tout" }}
      />
    );
  }

  setAppTitle("Accueil");

  return (
    <React.Fragment>
      
      <Navbar key="nav-admin">
        <Routes>
           {routes.filter(
              (p) =>
                p.layout === "/home" &&
                userHelper.isVisible(p.visibility, userAdmin, p.subscribeable, true)
              ).map((p, index)=>{
                return (
                  <Route
                    path={`${p.path}`}
                    element={<p.component />}
                    key={`${p.name}${index}`}
                  />
                );
              })}
            <Route path={`*`} element={<NotFoundScreen />} key={`notfound`} />
         </Routes>
      </Navbar>
    </React.Fragment>
  );
}

/**
 * 
 * <SplashScreen
        loading={loading || isEmpty(user)}
        setLoading={setLoading}
      />
 */