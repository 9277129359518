import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SurveyData {
  profil: any;
  current: number;
  list: any[];
}

const initialState: SurveyData = {
  profil: {},
  current: 1, //0
  list: [],
};

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveyProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrentSurvey: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setListSurvey: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
  },
});
export const {
  setSurveyProfil,
  setCurrentSurvey,
  setListSurvey,
} = surveySlice.actions;
export default surveySlice.reducer;

