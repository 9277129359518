import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QuizData {
  profil: any;
  current: any;
  list: any[];
  all:any[];
}

const initialState: QuizData = {
  profil: {},
  current: {},
  list: [],
  all:[],
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setListQuiz: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setListAllQuiz: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const {
  setProfil,
  setCurrent,
  setListQuiz,
  setListAllQuiz,
} = quizSlice.actions;
export default quizSlice.reducer;

