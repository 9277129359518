import { CCarousel, CCarouselCaption, CCarouselItem, CImage } from '@coreui/react';
import { Image } from 'antd';
import PropTypes from "prop-types";
import React from 'react'
//import '@coreui/coreui/dist/css/coreui.min.css';

export default function CarrouselBoostrap({pictures}) {
  return (
    <React.Fragment>
       <CCarousel activeIndex={0} controls indicators interval transition='slide' touch dark>
          {pictures
               .map((c,i)=>{
                return (
                    <CCarouselItem active interval style={{height: "400px", width: "100%"}} key={i} >
                      <CImage className="d-block w-100" src={c?.photo} alt={`Slide ${i+1}`} />
                      <CCarouselCaption className="d-none d-md-block">
                        <h5>Photo n°{i+1}</h5>
                        <p>{c.content}</p>
                        <p style={{fontSize: 10}}>{c.photo}</p>
                      </CCarouselCaption>
                    </CCarouselItem>
                )
            })}
       </CCarousel>
    </React.Fragment>
  );
}

CarrouselBoostrap.propTypes = {
    pictures:PropTypes.any,
  }; 

  /**
   * 
   * <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
            <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          {pictures
               .map((c,i)=>{
                return <div className="carousel-item" key={i}>
                  <Image
                    className="d-block mx-auto"
                    style={{height: "400px", width: "400px"}}
                    src={c.photo}
                   />
                   <div className="carousel-caption d-none d-md-block">
            
                   </div>
                </div>
            })}
        </div>
        <button className="carousel-control-prev" type="button" data-target="#carouselExampleCaptions" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-target="#carouselExampleCaptions" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
        </button>
      </div>
   */
