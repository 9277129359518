import React from "react";
import { Empty, Layout } from "antd";
import { filter } from "lodash";
import { ExportData } from "../../components/export";
import { getColumnByPeople } from "../../utils/helpers/exportHelper";
import userHelper from "../../utils/helpers/userHelper";
import { useDispatch, useSelector } from "react-redux";

export function ExplorePage() {

  const columns = useSelector((state) => state.treatment.columns);
  const current = userHelper.getSurveyData();
  const loading = useSelector((state) => state.treatment.loading);

  return (
    <Layout>
      <ExportData title={current.sigle}
        columns={columns}
        loading={loading}
      />

    </Layout>
  );
}