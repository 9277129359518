import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter } from "lodash";

export interface TreatmentData {
  profil: any;
  reponse: any;
  section:any;
  current:any;
  list: any[];
  all: any[];
  columns:any[];
  data:any[];
  loading:boolean;
}

const initialState: TreatmentData = {
  profil: {},
  reponse:{},
  section:{},
  current:{},
  list: [],
  all: [],
  columns:[],
  data:[],
  loading:true,
};

export const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {
    setTreatmentCurrent: (state, action: PayloadAction<any>) => {
        state.current = filter(state.list,{"id":action.payload})[0];
    },
    setTreatmentProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setTreatmentReponse: (state, action: PayloadAction<any>) => {
      state.reponse = action.payload;
    },
    setTreatmentSection: (state, action: PayloadAction<any>) => {
        state.section = action.payload;
    },
    setTreatmentList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setTreatmentAll: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setTreatmentColumns: (state, action: PayloadAction<any[]>) => {
      state.columns = action.payload;
    },
    setTreatmentData: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    setTreatmentLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
export const {
  setTreatmentCurrent,
  setTreatmentProfil,
  setTreatmentReponse,
  setTreatmentSection,
  setTreatmentList,
  setTreatmentAll,
  setTreatmentColumns,
  setTreatmentData,
  setTreatmentLoading,
} = treatmentSlice.actions;
export default treatmentSlice.reducer;

