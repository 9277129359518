import { Grid, Paper } from '@mui/material';
import {Layout} from 'antd'
import { filter} from 'lodash';
import React from 'react';
import { IagCardChartProfile } from '../../components/cards';
import { useQueryClient } from '@tanstack/react-query';
import userHelper from '../../utils/helpers/userHelper';
const {Content} = Layout;

export function SectionLayout() {
const queryClient = useQueryClient();
const sections = queryClient.getQueryData(['sections']) || [];
const current = userHelper.getSurveyData();

function ChoiceChart(item,index){
   switch (index) {
   case 0:
       return <IagCardChartProfile title={item.title} typeChart="bar" orientation={"vertical"} items={item} />;
   case 1:
       return <IagCardChartProfile title={item.title} typeChart="bar" orientation={"horizontal"} items={item} />;
   case 2:
       return <IagCardChartProfile title={item.title} typeChart="pie" orientation={"circle"} items={item} />;
   case 3:
       return <IagCardChartProfile title={item.title} typeChart="bar" orientation={"horizontal"} items={item} />;
   default:
       return <IagCardChartProfile title={item.title} typeChart="pie" orientation={"mi-circle"} items={item} />;
   }
}
  return (
    <Content style={{margin:"2px 5px", overflow:"hidden"}}>
       <Grid columnSpacing={{ xs: 0, sm: 0, md: 0.5 }} justifyContent={"center"} rowSpacing={0.5} container>
        {
          filter(sections,{"investigation_id":current.id}).map((item,index)=>{
            return (
              <Grid key={index} xs={12} sm={12} md={6} item>
                <Paper elevation={0} style={{width:"100%",height:"100%"}}>
                  {ChoiceChart(item, index)}
                </Paper>
              </Grid>
            );
          })
        }
      </Grid>
    </Content>
  )
}