import { Breadcrumb, Checkbox, CheckboxOptionType, Divider, Layout, Typography } from 'antd';
import { filter, isEmpty, uniqBy } from 'lodash';
import React from 'react'
import chartHelper from '../../utils/helpers/chartHelper';
import 'scrollable-component';
import formatHelper from '../../utils/helpers/formatHelper';
import userHelper from '../../utils/helpers/userHelper';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setBasicStats, setCoordinates, setDashboardFilters, setDashboardLoaded, setDashboardStatics, setLegends, setQuestions } from '../../redux/dashboardReducer';
import dataHelper from '../../utils/helpers/dataHelper';
import IWorker from '../../services/workers/IWorker';
import mapWorker from '../../services/workers/mapWorker';
import quizWorker from '../../services/workers/quizWorker';

const FilterView: React.FC<{
  loadingQuiz?: boolean; setLoadingQuiz?: any;
  loadingMap?: boolean; setLoadingMap?: any;
}> = ({ loadingMap, setLoadingMap, loadingQuiz, setLoadingQuiz }) => {

  const queryClient = useQueryClient();
  const current = userHelper.getSurveyData();
  const userAdmin = userHelper.getData();
  const researchs = queryClient.getQueryData(['researchs']) || [];
  const filterList = useSelector((state: RootState) => state.dashboard.filters);
  const filterSearchList = useSelector((state: RootState) => state.research.all);
  const percent = useSelector((state: RootState) => state.dashboard.percent);
  const dispatch = useDispatch();
  const peoples = useSelector((state: RootState) => state.people.all);
  const questions = useSelector((state: RootState) => state.quiz.all);
  const statics = useSelector((state: RootState) => state.dashboard.statics);
  const legends = useSelector((state: RootState) => state.legende.all);
  const sections = useSelector((state: RootState) => state.section.all);
  const loaded = useSelector((state: RootState) => state.dashboard.loaded);

  const [options, setOptions] = React.useState<{value:string;label:string;options:(string | number | CheckboxOptionType)[]}[]>([]);
  const [gender, setGender] = React.useState([]);
  const [search, setSearch] = React.useState([]);
  const [obj, setObj] = React.useState({});
  const [initialized, setInitialized] = React.useState(false);

  let instance = new IWorker(mapWorker);
  let instanceQuiz = new IWorker(quizWorker);
  // onLoadMessageMap();
  // onLoadMessageQuiz();

  function onLoadMessageMap() {
    instance = new IWorker(mapWorker);
    instance.onmessage = (message) => {
      const data = message.data ?? {};
      const peopleList: any[] = uniqBy(data.peoples, "id") ?? [];
      // const feminine = uniqBy(data.feminine, "id") ?? 0;
      // const male = uniqBy(data.male, "id") ?? 0;
      const male = peopleList.filter(p=>p.gender?.toLowerCase() === "m")?.length ?? 0;
      const feminine = peopleList.filter(p=>p.gender?.toLowerCase() === "f")?.length ?? 0;
      const coordinates = peopleList.map(
        (p: any) => ({
          ...p,
          longitude: isEmpty(p.longitude) ? 0.00 : p.longitude,
          latitude: isEmpty(p.latitude) ? 0.00 : p.latitude,
          id:p.id,
        })
      )
      dispatch(setCoordinates(coordinates));
      dispatch(setBasicStats({ feminine, male, total: male + feminine }))
      dispatch(setDashboardLoaded(true));
      setLoadingMap(false);
    }
  }

  function onLoadMessageQuiz() {
    instanceQuiz = new IWorker(quizWorker);
    instanceQuiz.onmessage = (message) => {
      const data = message.data ?? {};
      const list = data.questions ?? [];
      const stats = data.statics ?? [];
      const legendList = data.legends ?? [];
      dispatch(setQuestions(list));
      dispatch(setLegends(legendList));
      if(stats.length>0){
        dispatch(setDashboardStatics(statics));
      }
      dispatch(setDashboardLoaded(true));
      setLoadingQuiz(false);
    }
  }



  const [optionGenders] = React.useState([
    { "value": "M", "label": "Masculin" },
    { "value": "F", "label": "Féminin" }
  ]);

  const onChange = (e: any) => {
    const items = [...filterList];
    let list = items.filter(p => (p.key !== "section"));
    e?.map((p: any) => {
      list.push({ key: "section", type: "map", value: p })
      list.push({ key: "section", type: "quiz.response.people", value: p })
    })
    dispatch(setDashboardFilters(list));
  };

  const onChangeGender = (e: any) => {
    const items = [...filterList];
    let list = items.filter(p => (p.key !== "gender"));
    e?.map((p: any) => {
      list.push({ key: "gender", type: "map", value: p })
      list.push({ key: "gender", type: "quiz.response.people", value: p })
    })
    dispatch(setDashboardFilters(list));
    setGender(e);
  }

  React.useEffect(() => {
    if(initialized){
      onFilter();
    }else if(!loaded){
      onFilter();
    }else{
      setInitialized(true);
    }
  }, [filterList])

  const onFilter = React.useCallback(async () => {
    // if (loaded === false) {
    //   return;
    // }
    instance.terminate();
    instanceQuiz.terminate();
    onLoadMessageMap();
    onLoadMessageQuiz();
    setLoadingMap(true);
    setLoadingQuiz(true);
    instance.postMessage({
      peoples, filters: uniqBy(dataHelper.getFilters("map", filterList), "value"),
      quiz: questions,
    },
    );
    instanceQuiz.postMessage({
      quiz: questions,
      filterQuiz: dataHelper.getFilters("quiz.response.people", filterList),
      statics,
      legends:legends.filter((p:any)=>p?.investigation_id === current?.id),
    },
    );
  }, [filterList]);

  const onChangeResearch = (e: any, r: any) => {
    const items = [...filterList];
    let list = items.filter(p => (p.key !== "filters"));
    list = list.filter(p => (p.key !== ""));
    e?.map((p: any) => {
      let le = { key: "filters", type: "map", value: e, rule: r.rule, column_id: r.column_id }
      list.push(le);
      let li = { key: "filters", type: "quiz.response.people", value: e, rule: r.rule, column_id: r.column_id }
      list.push(li);
    })
    dispatch(setDashboardFilters(list));
    setSearch(e);
    setObj(r);
  }

  React.useEffect(()=>{
    const list = sections.filter((p:any)=>p.investigation_id == current?.id);
    const items:{value:string;label:string;options:(string | number | CheckboxOptionType)[]}[] = [];
    list.map((p:any)=>{
      const contents:(string | number | CheckboxOptionType)[] = [];
      p.contents?.map((q:any)=>{
        contents.push({
          label:q.subject,
          value:q.subject,
        })
      })
      items.push({
        label:p.title,
        value:`${p.id}`,
        options:contents
      })
    });
    setOptions(items);
  },[sections])

  /*
  React.useEffect(()=>{
    dispatch(setListPeopleId({
      "key":formatHelper.reformObject(obj).column,
      "values": formatHelper.filterByQuery(
        filter(peoples,{"investigation_id":current.id}),
        formatHelper.reformObject(obj),
        search).map((p)=>p.id)
      })
    );
  
  },[search,obj])*/

  /*
  React.useEffect(()=>{
    const opts = [];
    filter(sections,{"investigation_id":current.id})
      .map((section)=>{
        section.contents.forEach(e => {
        opts.push({"value":e.subject,"label":e.subject});
      })
    })
    setOptions(opts);
  },[current,sections])*/

  /*React.useEffect(()=>{
    dispatch(setListPeopleId({"key":"remove","values":[]}));
  },[current]);*/

  return (
    <div style={{ margin: "0px 10px 10px 10px" }}>
      <Layout.Content style={{ alignItems: "center" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Survey</Breadcrumb.Item>
          <Breadcrumb.Item>
            {current.sigle}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Layout.Content>
      <Divider />
      <Layout.Content style={{ alignItems: "center", margin: "0px 5px" }}>
        <Typography.Title level={5} style={{ margin: "0px 0px 5px 0px" }}>{"SEXE"}</Typography.Title>
        <Checkbox.Group options={optionGenders} onChange={onChangeGender} />
      </Layout.Content>
      {
        /**@ts-ignore */
        filter(filterSearchList, { "investigation_id": current.id }).map((r, k) => (
          <React.Fragment key={k}>
            <Layout.Content style={{ alignItems: "center", margin: "5px 0px" }}>
              { /**@ts-ignore */}
              <Typography.Title level={5} style={{ margin: "5px 0px" }}>{r.title}</Typography.Title>
              { /**@ts-ignore */}
              <Checkbox.Group options={r.fields.map((f) => ({ "value": f.contents, "label": f.name }))}
                onChange={e => onChangeResearch(e, r)}
              />
            </Layout.Content>
          </React.Fragment>
        ))
      }
      {
        options.length > 0 && <Layout.Content style={{ alignItems: "center", margin: "5px 0px" }}>
          <Typography.Title level={5} style={{ margin: "5px 0px" }}>{"SECTIONS"}</Typography.Title>
          {options.map((item:any,index:number)=>(
            <div key={`${index}_${item.value}`} style={{padding:"0 5px"}}>
              <Typography.Text strong color='gold'>{item.label}</Typography.Text>
              <div>
              <Checkbox.Group options={item.options ?? []} onChange={onChange} />
              </div>
            </div>
          ))}
        </Layout.Content>
      }
    </div>
  )
}

/**
 *  <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RDC"}</Typography.Title>
            <Checkbox.Group options={optionPhones} onChange={onChangePhone} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RCA"}</Typography.Title>
            <Checkbox.Group options={optionPhonesRca} onChange={onChangePhoneRca} />
          </Layout.Content>
 *
 *
 *
 *
 *  <scrollable-component style={{height:"80vh"}} vertical-scrollbar-position="right">
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"0px 0px 5px 0px"}}>{"SEXE"}</Typography.Title>
            <Checkbox.Group options={optionGenders} onChange={onChangeGender} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RDC"}</Typography.Title>
            <Checkbox.Group options={optionPhones} onChange={onChangePhone} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RCA"}</Typography.Title>
            <Checkbox.Group options={optionPhonesRca} onChange={onChangePhoneRca} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center", margin:"5px 0px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"SECTIONS"}</Typography.Title>
            <Checkbox.Group options={options} onChange={onChange} />
          </Layout.Content>
      </scrollable-component>
 */
export default FilterView;