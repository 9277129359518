import moment from "moment";

class DateHelper {

    formatStringToDate(date:string):any|null{
        //return moment(date).format('MMM DD YYYY, h:mm:ss a'); // November 1st 2022, 3:53:39 pm
        //return moment(date).fromNow();
      return moment(date).calendar();
    }

    getNow(date:string):any|null{
      return moment(date).fromNow();
    }
    
    toDateAndTime(date:string):any|null{
      return new Date(date).toLocaleString();
    }

    toDate(date:string):any|null{
      return moment(date).format("DD MMM YYYY");
    }
  }
  
  export default new DateHelper();