
// import _ from "lodash";
/* eslint-disable import/no-anonymous-default-export */

export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message:any) => {
    const data = message.data;
    const peoples:any[] = data?.peoples ?? [];
    const filters:any[] = data?.filters ?? [];
    let peopleList:any[] = peoples;
    const genders = filters.filter(p=>p.key === "gender");
    const filtres = filters.filter(p=>p.key === "filters");
    const sections = filters.filter(p=>p.key === "section" && p.type ==="map");
    if(genders?.length === 1){
        peopleList = peoples.filter((p:any)=>genders.findIndex((x:any)=>x.value === p?.gender)!=-1);
    }
    if(filtres?.length > 0){
      let filtItem = filtres[0];
      // if (filtItem.rule === 'starts') {
        let elems: any[] = [];
        for (let index = 0; index < filtItem.value.length; index++) {
          for (let idx = 0; idx < filtItem.value[index].length; idx++) {
            elems.push(filtItem.value[index][idx]);
          }
        }
        let peop : any[] = [];
        switch (filtItem.rule) {
          case 'starts':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id]?.startsWith(elems[idx])) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'ends':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id]?.endsWith(elems[idx])) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'eq':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id] === elems[idx].trim()) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'contains':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id].includes(elems[idx].trim())) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'lt':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id] < elems[idx]) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'gt':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id] > elems[idx]) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'lte':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id] <= elems[idx]) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
          case 'gte':
            for (let index = 0; index < peopleList.length; index++) {
              for (let idx = 0; idx < elems.length; idx++) { 
                if (peopleList[index][filtItem.column_id] >= elems[idx]) {
                  peop.push(peopleList[index]);
                }
              }
            }

            break;
        
          default:
            peop = peopleList;
            break;
        }

        peopleList = peop;
    }
    if(sections.length>0){
      peopleList = peopleList.filter((p:any)=>{
        return p?.sections.filter((q:any)=>{
          return sections.findIndex((r:any)=>r.value === q.subject)!==-1;
        })?.length > 0;
      })
    }
    const male = peopleList.filter(p=>p.gender?.toLowerCase() === "m")?.length;
    const feminine = peopleList.filter(p=>p.gender?.toLowerCase() === "f")?.length;
    const result = {
        peoples:peopleList,
        male,
        feminine,
    }
    postMessage(result);
  };
};

