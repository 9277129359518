import React from 'react'
import { Col,Layout,Row, Typography } from 'antd';
import PropTypes from "prop-types";
import { IagChartCirclePie, IagChartHorizontal, IagChartMiCirclePie, IagChartVertical } from '../charts';
import { filter, groupBy} from 'lodash';
import textHelper from '../../utils/helpers/textHelper';
import chartHelper from '../../utils/helpers/chartHelper';
import ApexBarCustom from '../charts/apexBar';
import { useQueryClient } from '@tanstack/react-query';
import userHelper from '../../utils/helpers/userHelper';
const { Content } = Layout;

export default function App(
    {title,typeChart,orientation,items}
) {

const queryClient = useQueryClient();
const peoples = queryClient.getQueryData(['peoples']) || [];
const provinces = queryClient.getQueryData(['provinces']) || [];
const current = userHelper.getSurveyData();

  return (
    <Layout.Content className='iag-card-chart'>
        <Row wrap={false}>
          <Col>
            <Typography.Title level={5}>
              {title}
            </Typography.Title>
          </Col>
        </Row>
        <Content>
          {typeChart==="bar" && 
            orientation==="horizontal" && 
            <ApexBarCustom 
              data={chartHelper.groupData(filter(peoples,{"investigation_id":current.id}), title, "subject")}
             />
           }
          {typeChart==="bar" && 
            orientation==="vertical" && 
            <IagChartVertical data={chartHelper.groupData(filter(peoples,{"investigation_id":current.id}), title, "subject")} 
             xField="type" yField='value' serieField={"value"} orientation="vertical" />}
          {typeChart==="pie" && 
           orientation==="circle" && 
           <IagChartCirclePie title={title} data={chartHelper.groupData(filter(peoples,{"investigation_id":current.id}), title, "subject")} plein="plein" />}
          {typeChart==="pie" && 
           orientation==="mi-circle" && 
           <IagChartMiCirclePie title={title} data={
            textHelper.groupBySection("name",filter(provinces,{"investigation_id":current.id}),
            groupBy(filter(peoples,{"investigation_id":current.id}),"province"))
           } plein="miCircle" />}
        </Content>
    </Layout.Content>
  )
}
App.propTypes = {
  title: PropTypes.string,
  typeChart:PropTypes.string,
  orientation:PropTypes.string,
  items:PropTypes.any,
  //formik: PropTypes.any,
};  

/**
 *  <IagChartHorizontal 
              data={chartHelper.groupData(filter(peoples,{"investigation_id":surveyId}), title, "subject")} 
             xField="value" yField='type' 
             serieField={"type"} orientation="horizontal" />
 */