import { UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Typography } from "antd";
import { isEmpty, toUpper, upperCase, upperFirst } from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../../routes";
import { ProfilContent } from "../../../layouts/menus/home/menu"; 
import userHelper from "../../../utils/helpers/userHelper";

interface siderbarInterface {
  current: string;
  setVisible?: any;
}

export function SidebarMenu(props: siderbarInterface) {
  const { current, setVisible } = props;

  const user = userHelper.getData();
  const routerLocation = useLocation();

  function handleClose() {
    if (typeof setVisible === "function") {
      setVisible(false);
    }
  }

  React.useEffect(() => {
    if (typeof setVisible === "function") {
      setVisible(false);
    }
  }, [routerLocation]);

  return (
    <React.Fragment>
      <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 10px",
          }}
        >
          <Avatar
              size={100}
              style={{ background: "#f1f1f1" }}
              src={isEmpty(user.photo) ? null : user.photo}
            >
              {isEmpty(user.photo) && (
                <UserOutlined style={{ color: "#000", fontSize:"30px"}} />
              )}
            </Avatar>
          <Typography.Text>
            {`${upperFirst(user.firstName)} ${upperCase(user.lastName)}`}
          </Typography.Text>
        </div>
        <ul className="yc-menu-list" style={{ flex: 1 }}>
          {routes
            .filter((p) => p.layout === "/home" &&  p.isMenu)
            .map((p, index) => {
              return (
                <li
                  onClick={handleClose}
                  className="yc-menu-li"
                  key={`sidebar${index}`}
                >
                  <Link
                    className={`${
                      p.layout + p.path === current ? "yc-active" : ""
                    }`}
                    to={`${p.layout}${p.path}`}
                  >
                    {toUpper(p.label)}
                  </Link>
                </li>
              );
            })}
        </ul>
        <Divider style={{ margin: 0 }} />
          <ProfilContent />
        <Divider style={{ margin: 0 }} />
      </div>
    </React.Fragment>
  );
}
