import { assign, filter, parseInt, replace, startsWith, toNumber, toPlainObject, trim } from 'lodash';
import React from 'react';
import { getNumberOnly } from './title';

declare type fixedType = "left" | "right" | null;

interface ColumnType{
  title:string,
  dataIndex:string,
  key:React.Key,
  width?:any,
  fixed?:fixedType
}

class TableHelper{

   getColumnsTable(data:any[],isFixed:boolean,firstIndex:boolean,lastIndex:boolean):ColumnType[] | null{
    var columns: ColumnType[] = [];
    data.map((d,k)=>{
        columns.push({"title":d.title,"dataIndex":d.key,"key":d.key,
        "fixed": null,
         "width": 400,
         });
    })
    return columns;
   }

   getDataTable(columns:any,data:any[],dicts:any):any[] | null{
     var list : any[] = [];
     
     return list;
   }

   getWidthByKey(key:string):number | null{
    switch(key){
      case "fiche":
        return 120;
      case "gender":
        return 40;
      case "created_at":
        return 120;
      case "updated_at":
        return 120;
      case "user":
        return 140;
      case "province":
        return 100;
      case "common":
        return 100;
      case "quarter":
        return 100;
      case "phone":
        return 100;
      default:
        return 280;
    }
   }

  
   getColumQuiz(quizzes:any[]):any[]| null{
    let qz:any[] = quizzes.map((q)=>(
      {title:q.sigle+"  "+q.description,key:q.id,minWidth:400}
    ))
    return qz;
   }

   createJsonToExport(list:any[],columns:any[]):any[]|null{
    const data : any[] = [];
    list.map((row,i) => {
      const keys = new Map();
      columns.map((column) => {
        const check = this.checkColumnQuiz(row, column.key);
        if(check == null){
          const value = row[column.key];
          keys.set(column.key,value);
        }
        else{
          keys.set(column.key,check);
        }
      })
      data.push(Object.fromEntries(keys));
    });
    return data;
   }

   checkColumnQuiz(row:any,key:string):any|null{
    let r:any[] = filter(row['people_id'],{"quiz_id":parseInt(getNumberOnly(key))})
    if(r.length == 0){
      let s:any[] = filter(row['section'],{"id": this.getId(key)})
      if(s.length ==0){
        return null;
      }
      else{
        return s[0]['subject'];
      }
    }
    else{
      return r[0]['is_image']==1 ? r[0]['photo'] : r[0]['content'];
    }
   }

   getId(key:string):number | null{
    if(startsWith(key,"sec_")){
      return parseInt(trim(replace(key,"sec_","")));
    }
    else{
      return null;
    }
   }


   checkByKey(key:string):boolean{
    switch(key){
      case "id":
        return false;
      case "fiche":
        return false;
      case "gender":
        return false;
      case "created_at":
        return false;
      case "updated_at":
        return false;
      case "user":
        return false;
      case "province":
        return false;
      case "common":
        return false;
      case "quarter":
        return false;
      case "phone":
        return false;
      case "section":
        return false;
      case "prefecture":
        return false;
      case "sub_prefecture":
        return false;
      case "survey_object_name":
        return false;
      case "photo":
        return false;
      case "people_id":
        return false;
      case "action":
        return true;
      case "position":
        return false;
      default:
        return true;
    }
   }

}

export default new TableHelper();

interface Reponse {
  quizId: number;
  content: string;
}


/*const columns = [
  {
    title: "FICHE",
    key: "fiche",
  },
  {
    title: "SEXE",
    key: "gender",
  },
  {
    title: "PHONE",
    key: "phone",
  },
  {
    title: "PROVINCE",
    key: "province",
  },
  {
    title: "COMMUNE",
    key: "common",
  },
  {
    title: "QUARTIER",
    key: "quarter",
  },
  {
    title:"SECTIONS",
    key:"section",
  },
  {
    title: "LATITUDE & LONGITUDE",
    key: "position",
  },
  {
    title: "DATE SOUSMISSION",
    key: "created_at",
  },
  {
    title: "ENQUETEUR",
    key: "user",
  },
  {
    title: "ACTION",
    key: "action",
  }
];*/


/***
 * 
 *  getColumnByPeople(sec:boolean,quizzes:any[]):any[] | null{
    return sec ? [
      {
        title: "FICHE",
        key: "fiche",
        minWidth:170,
      },
      {
        title: "SEXE",
        key: "gender",
        minWidth:170,
      },
      {
        title: "PHONE",
        key: "phone",
        minWidth:170,
      },
      {
        title: "PROVINCE",
        key: "province",
        minWidth:200,
      },
      {
        title: "COMMUNE",
        key: "common",
        minWidth:200,
      },
      {
        title: "QUARTIER",
        key: "quarter",
        minWidth:200,
      },
      {
        title:"SECTIONS",
        key:"section",
        minWidth:200,
      },
      {
        title: "PREFECTURE | CHEF-LIEU",
        key: "prefecture",
        minWidth:230,
      },
      {
        title: "SOUS PREFECTURE",
        key: "sub_prefecture",
        minWidth:230,
      },
      {
        title: "OBJET ENQUETE",
        key: "survey_object_name",
        minWidth:230,
      },
      {
        title: "LATITUDE & LONGITUDE",
        key: "position",
        minWidth:230,
      },
      {
        title: "DATE SOUSMISSION",
        key: "created_at",
        minWidth:200,
      },
      {
        title: "ENQUETEUR",
        key: "user",
        minWidth:200,
      },
      {
        title: "ACTION",
        key: "action",
      }
    ]: [
        {
          title: "FICHE",
          key: "fiche",
          minWidth:170,
        },
        {
          title: "SEXE",
          key: "gender",
          minWidth:170,
        },
        {
          title: "PHONE",
          key: "phone",
          minWidth:170,
        },
        {
          title: "PROVINCE",
          key: "province",
          minWidth:200,
        },
        {
          title: "COMMUNE",
          key: "common",
          minWidth:200,
        },
        {
          title: "QUARTIER",
          key: "quarter",
          minWidth:200,
        },
        {
          title: "PREFECTURE | CHEF-LIEU",
          key: "prefecture",
          minWidth:230,
        },
        {
          title: "SOUS PREFECTURE",
          key: "sub_prefecture",
          minWidth:230,
        },
        {
          title: "OBJET ENQUETE",
          key: "survey_object_name",
          minWidth:230,
        },
        {
          title: "LATITUDE & LONGITUDE",
          key: "position",
          minWidth:230,
        },
        {
          title: "DATE SOUSMISSION",
          key: "created_at",
          minWidth:200,
        },
        {
          title: "ENQUETEUR",
          key: "user",
          minWidth:200,
        },
        {
          title: "ACTION",
          key: "action",
        }
      ];
   }

 */