import React from "react";
import { Breadcrumb, Layout, Typography } from "antd";
import { setAppTitle } from "../../utils/helpers/title";
import { useDispatch } from "react-redux";
import FilterView from "../../layouts/filters/filter";
import userHelper from "../../utils/helpers/userHelper";
import { MeMap } from "./molecule/map";
import { MeQuiz } from "./molecule/quiz";
import { MeStatTotal } from "./molecule/statTotal";
import { MeStatGenre } from "./molecule/statGenre";
import { MeLegend } from "./molecule/legend";
import { useSelector } from "react-redux";
import { onChildAdded, ref, onValue, off } from "firebase/database";
import { RootState } from "../../redux/store";
import dataHelper from "../../utils/helpers/dataHelper";
import { database } from "../../firebase/config";

export function DashboardPage() {

  const current = userHelper.getSurveyData();
  const stats = useSelector((state: RootState) => state.dashboard.basicStats);
  const [loadingQuiz, setLoadingQuiz] = React.useState(false);
  const [loadingMap, setLoadingMap] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const dataRef = ref(database, 'surveys/survey_' + current.id);
    onChildAdded(dataRef, async (snapshot) => {
      await dataHelper.initData(dispatch);
    });
    return () => { off(dataRef); }
  }, []);

  setAppTitle("Dashboard");

  return (
    <Layout.Content className="container-row">
      <Layout className="container-left">
        <Layout.Content className="fixed-filter">
          <FilterView
            loadingMap={loadingMap} setLoadingMap={setLoadingMap}
            loadingQuiz={loadingQuiz} setLoadingQuiz={setLoadingQuiz}
          />
        </Layout.Content>
      </Layout>

      <Layout className="container-center">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>Survey</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Typography.Title level={5}>{current?.sigle}</Typography.Title>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <MeMap loading={loadingMap} />
        <MeQuiz loading={loadingQuiz} />
      </Layout>

      <Layout className="container-right">
        <Layout.Content className="fixed-sync">
          <div>
            <Typography.Text style={{ margin: 0 }}>Statistiques élémentaires</Typography.Text>
          </div>
          <MeStatTotal value={stats.total} loading={loadingMap} />
          <MeStatGenre title="Masculin " color="primary" value={stats.male} loading={loadingMap} />
          <MeStatGenre title="Féminin " color="secondary" value={stats.feminine} loading={loadingMap} />
          <MeLegend loading={loadingQuiz} />
        </Layout.Content>
      </Layout>
    </Layout.Content>
  );
}