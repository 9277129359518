import React from 'react';
import { Table } from 'antd';

export default function TableSimpleCustom(props) {
  const {columns,list} = props;

  return (
    <>
      <Table 
          columns={columns}
          dataSource={list}
          scroll={{x: "100%"}}
      >
     </Table>
    </>
  );
}
