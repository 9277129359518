
// import _ from "lodash";
/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line import/no-webpack-loader-syntax

export default () => {
  "use strict";
  //@ts-ignore
  // eslint-disable-next-line no-restricted-globals
  //self.importScripts('/src/utils/helpers/formatHelper');
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message: any) => {
    const data = message.data;
    const peoples: any[] = data?.peoples ?? [];
    const responses: any[] = data?.responses ?? [];
    const sections: any[] = data?.sections ?? [];
    const quizzes: any[] = data?.quizzes ?? [];
    const users: any[] = data?.users ?? [];
    const current: any = data?.current ?? {};

    const columns = [
      {
        title: "ID",
        key: "id",
        dataIndex:'id',
        fixed:'left',
        width:85,
      },
      {
        title: "FICHE",
        key: "fiche",
        dataIndex:'fiche',
        fixed: null,
        width:170,
      },
      {
        title: "SEXE",
        key: "gender",
        dataIndex:'gender',
        fixed: null,
        width:105,
      },
      {
        title: "PHONE",
        key: "phone",
        dataIndex:'phone',
        fixed: null,
        width:170,
      },
      {
        title: "PROVINCE",
        key: "province",
        dataIndex:'province',
        fixed: null,
        width:200,
      },
      {
        title: "COMMUNE",
        key: "common",
        dataIndex:'common',
        fixed: null,
        width:200,
      },
      {
        title: "QUARTIER",
        key: "quarter",
        dataIndex:'quarter',
        fixed: null,
        width:200,
      },
      {
        title: "PREFECTURE | CHEF-LIEU",
        key: "prefecture",
        dataIndex:'prefecture',
        fixed: null,
        width:230,
      },
      {
        title: "SOUS PREFECTURE",
        key: "sub_prefecture",
        dataIndex:'sub_prefecture',
        fixed: null,
        width:230,
      },
      {
        title: "OBJET ENQUETE",
        key: "survey_object_name",
        dataIndex:'survey_object_name',
        fixed: null,
        width:250,
      },
      {
        title:"PHOTO",
        key: "photo",
        dataIndex:'photo',
        fixed: null,
        width:825,
      },
      {
        title: "LATITUDE & LONGITUDE",
        key: "position",
        dataIndex:'position',
        fixed: null,
        width:230,
      },
      {
        title: "DATE SOUSMISSION",
        key: "created_at",
        dataIndex:'created_at',
        fixed: null,
        width:200,
      },
      {
        title: "ENQUETEUR",
        key: "user",
        dataIndex:'user',
        fixed: null,
        width:200,
      }
      
    ];

    function getFlatingDataSurvey(peoples: any[], responses: any[], users: any[], current: any): any[] {
      const data: any[] = [];
      const currentResponses: any[] = responses;
      peoples
        .map((p) => {
          const reps = currentResponses?.filter(x => x.people_id === p.id);
          const v = toObjectInsert(reps, getPeople(p.sections.length > 0, p, users, reps));
          data.push(v);
        });

      return data;
    }

    function toObjectInsert(responses: any[], data: any[]) {
      const result = responses.reduce((d, v) => ({ ...d, ["q_" + v.quiz_id]: v.is_image == 0 ? v.content : v.photo, ["r_" + v.quiz_id]: v.id }), {});
      Object.assign(result, data);
      return data;
    }

    function getPeople(hasSection: boolean, people: any, users: any[], reponses: any[]): any {
      return hasSection ? {
        "id": people.id,
        "fiche": people.form,
        "gender": people.gender,
        "phone": people.phone,
        "province": people.province,
        "common": people.common,
        "quarter": people.quarter,
        "position": people.latitude + ", " + people.longitude,
        "prefecture": people.prefecture,
        "sub_prefecture": people.sub_prefecture,
        "survey_object_name": people.survey_object_name,
        "user": getNameUser(users?.filter(p => p.id === people.user_id)[0]),
        "created_at": toDateAndTime(people.created_at),
        "photo": people.photo,
        "section": people.sections,
        "people_id": reponses,
        "investigation_id": people.investigation_id,
        "action": people
      } :
        {
          "id": people.id,
          "fiche": people.form,
          "gender": people.gender,
          "phone": people.phone,
          "province": people.province,
          "common": people.common,
          "quarter": people.quarter,
          "position": people.latitude + ", " + people.longitude,
          "prefecture": people.prefecture,
          "sub_prefecture": people.sub_prefecture,
          "survey_object_name": people.survey_object_name,
          "user": getNameUser(users?.filter(p => p.id === people.user_id)[0]),
          "created_at": toDateAndTime(people.created_at),
          "photo": people.photo,
          "people_id": reponses,
          "investigation_id": people.investigation_id,
          "action": people
        }
    }

    function getNameUser(user: any): string {
      let value = user;
      if (Array.isArray(user)) {
        value = user[0] ?? {};
      }
      let result = "";
      if (value) {
        result = `${(value?.first_name)?.toUpperCase()} ${(value?.last_name)?.toUpperCase()}`;
      }
      return result;
    }

    function toDateAndTime(date: string): any | null {
      return new Date(date).toLocaleString();
    }

    function getColumnByPeople(sections: any, quizzes: any) {
      var quiz = getColumQuiz(quizzes);
      var secs = getColumSection(sections);

      const list:any[] = [];

      columns.map((p:any)=>{
        if( !checkByKey(p.key)){
          list.push(p);
        }
      });

      //remove(columns, (c: any) => checkByKey(c.key));

      for (var i = 0; i < secs.length; i++) {
        //remove(columns, (c) => c.key == secs[i].key);
        //columns.push(secs[i]);
        list.push(secs[i]);
      }

      for (var i = 0; i < quiz.length; i++) {
        //remove(columns, (c) => c.key == quiz[i].key);
        //columns.push(quiz[i]);
        list.push(quiz[i]);
      }

      // columns.push({
      //   title: "ACTION",
      //   fixed: 'right',
      //   width: 105,
      //   key: "action",
      //   dataIndex: "action",
      //   align: "center",
      // });

      list.push({
        title: "ACTION",
        fixed: 'right',
        width: 105,
        key: "action",
        dataIndex: "action",
        align: "center",
      });

      return list;
    }

    function getColumQuiz(quizzes: any) {
      var qz = quizzes.map((q: any) => (
        { title: q.sigle + "  " + q.description, key: "q_" + toString(q.id), width: q.component == "image" ? 825 : 400, dataIndex: "q_" + toString(q.id), fixed: null }
      ))
      return qz;
    }

    function getColumSection(sections: any) {
      var secs = sections.map((q: any) => (
        { title: q.title, key: "sec_" + toString(q.id), width: 400, dataIndex: "sec_" + toString(q.id), fixed: null }
      ))
      return secs;
    }

    function checkByKey(key: string): boolean {
      switch (key) {
        case "id":
          return false;
        case "fiche":
          return false;
        case "gender":
          return false;
        case "created_at":
          return false;
        case "updated_at":
          return false;
        case "user":
          return false;
        case "province":
          return false;
        case "common":
          return false;
        case "quarter":
          return false;
        case "phone":
          return false;
        case "section":
          return false;
        case "prefecture":
          return false;
        case "sub_prefecture":
          return false;
        case "survey_object_name":
          return false;
        case "photo":
          return false;
        case "people_id":
          return false;
        case "action":
          return true;
        case "position":
          return false;
        default:
          return true;
      }
    }
    function toString(v: any) {
      return v;
    }
  
    let treats: any[] = [];
    treats = getFlatingDataSurvey(
      peoples, responses, users, current);
    let tableColumns:any[] = [];
    tableColumns = getColumnByPeople(sections,quizzes);
  
    postMessage({treats,columns:tableColumns});

  }
};

