import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import chartHelper from "../utils/helpers/chartHelper";

export interface PeopleData {
  profil: any;
  current: any;
  list: any[];
  all: any[];
  listIDS:any[];

  listPhone:any[];
  listGender:any[];
  listSection:any[];
}

const initialState: PeopleData = {
  profil: {},
  current: {},
  list: [],
  all: [],
  listIDS:[],

  listPhone:[],
  listGender:[],
  listSection:[]
};

export const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setListPeople: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setListAllPeople: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setListPeopleId:(state,action:PayloadAction<any>)=>{
      switch(action.payload.key){
        case "phone":
          state.listPhone = action.payload.values;
          state.listIDS = chartHelper.getListPeopleId(state.listPhone,state.listGender,state.listSection);
          break;
        case "gender":
          state.listGender = action.payload.values;
          state.listIDS = chartHelper.getListPeopleId(state.listPhone,state.listGender,state.listSection);
          break;
        case "section":
          state.listSection = action.payload.values;
          state.listIDS = chartHelper.getListPeopleId(state.listPhone,state.listGender,state.listSection);
          break;
        default:
          state.listSection = [];
          state.listGender = [];
          state.listPhone = [];
          state.listIDS = [];
          break;
      }
    },
    editPeople:(state,action:PayloadAction<any>)=>{
      const id = action.payload.id;
      const data = action.payload.data;
    },
    deletePeople: (state,action:PayloadAction<any>)=>{

    },
    deletePeoples: (state,action:PayloadAction<any[]>)=>{
      
    }
  },
});
export const {
  setProfil,
  setCurrent,
  setListPeople,
  setListAllPeople,
  setListPeopleId,
  deletePeople,
  deletePeoples,
  editPeople,
} = peopleSlice.actions;
export default peopleSlice.reducer;

