import React from "react";
import { Menu } from "antd";
import { routes } from "../../../routes";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ProfilMenu } from "../../../layouts/menus/home/menu"; 
import { isEmpty, toUpper } from "lodash";

function LeftMenu({ mode = "horizontal", current = "" }) {
  const [active, setActive] = React.useState(true);

  return (
    <Menu mode={mode} className="yc-navbar">
      {active && (
        <>
          {routes
            .filter(
              (p) =>p.layout === "/home" && p.isMenu)
            .map((p, index) => {
              if (isEmpty(p.parent)) {
                return (
                  <Menu.Item key={`${p.name}${index}`}>
                    <Link
                      className={`${
                        p.layout + p.path === current ? "yc-active" : ""
                      }`}
                      to={`${p.layout}${p.path}`}
                    >
                      {toUpper(p.label)}
                    </Link>
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.SubMenu key={p.parent} title={p.parent}>
                    {routes
                      .filter(
                        (x) => x.layout === "/home/" && x.parent === p.parent
                      )
                      .map((p, index) => {
                        return (
                          <Menu.Item key={`${p.name}${index}`}>
                            <Link
                              className={`${
                                p.layout + p.path === current ? "yc-active" : ""
                              }`}
                              to={`${p.layout}${p.path}`}
                            >
                              {toUpper(p.label)}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                  </Menu.SubMenu>
                );
              }
            })}
        </>
      )}
      
      <ProfilMenu />
    </Menu>
  );
}

LeftMenu.propTypes = {
  mode: PropTypes.string,
  current: PropTypes.string,
};

export default LeftMenu;
