import Crud from "./crud";
class PeopleService extends Crud{
  url = "peoples";
  destroy(id: string | number) {
      return this.axios.delete(`${this.url}/${id}`);
  }
  get(id: string | number) {
      return this.axios.get(`${this.url}/${id}`);
  }
  getAll() {
      return this.axios.get(`${this.url}`);
  }
  getByKey(query: string) {
    return this.axios.get(`${this.url}/${query}`);
  }
  getBySurveyKey(id:string,query: string) {
    return this.axios.get(`${this.url}/query/${id}?${query}`);
  }
  store(data: any) {
    return this.axios.post(`${this.url}`, data);
  }
  update(id: string | number, data: any) {
    return this.axios.put(`${this.url}/${id}`, data);
  }
}
export default new PeopleService();