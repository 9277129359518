/* eslint-disable import/no-anonymous-default-export */

export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message: any) => {
    const data = message.data;
    const filters: any[] = data?.filterQuiz ?? [];
    const quiz: any[] = data?.quiz ?? [];
    const statics: any[] = data?.statics ?? [];
    let legends: any[] = data?.legends ?? [];
    const filtres = filters.filter(p => p.key === "filters");
    legends.map(legend => {
      legend?.contents?.map((content: any) => {
        content.sum = 0;
      })
    })
    let questions: any[] = quiz;
    const items = [...statics];
    const genders = filters.filter(p => p.key === "gender");
    const sections = filters.filter(p => p.key === "section" && p.type === "quiz.response.people");
    if (genders?.length === 1) {
      const gender = genders[0];
      const staticKey = `gender-${gender.value}`;
      const index = items.findIndex(p => p.key === staticKey);
      if (index === -1) {
        const filterPeoples = filters.filter(p => p.type === "quiz.response.people" && p.key === "gender");
        questions = [];
        quiz.map((p: any) => {
          const selectReps: any[] = p.responses?.filter(
            (rep: any) => {
              return filterPeoples.findIndex((x: any) => {
                const currentPeople = rep?.people;
                let currentTarget = null;
                if(currentPeople!=undefined && currentPeople!=null){
                  currentTarget = currentPeople[x.key] ?? "";
                } 
                return x.value === currentTarget;
              }) !== -1;
            }
          )
          const temp: any = { ...p };
          temp.responses = selectReps;
          if (selectReps.length > 0) {
            questions.push(temp);
          }
        })
        items.push({ key: staticKey, items: questions });
      } else {
        questions = items[index]?.items ?? [];
      }

    }

    if (filtres?.length > 0 || sections?.length > 0) {
      const elements = [...questions];
      questions = [];
      let filtItem = filtres[0];
      let elems: any[] = [];
      for (let index = 0; index < filtItem?.value?.length; index++) {
        for (let idx = 0; idx < filtItem?.value[index]?.length; idx++) {
          elems.push(filtItem.value[index][idx]);
        }
      }
      elements.map((p: any, i: number) => {
        let selectReps: any[] = p.responses;


        if (filtres?.length > 0) {
          selectReps = selectReps?.filter(
            (rep: any) => {
              return elems.findIndex((x: any) => {
                switch (filtItem.rule) {
                  case 'starts':
                    return rep?.people[filtItem.column_id]?.startsWith(x);
                  case 'ends':
                    return rep?.people[filtItem.column_id]?.endsWith(x);
                  case 'eq':
                    return (rep?.people[filtItem.column_id] ?? "") === x?.trim();
                  case 'contains':
                    return rep?.people[filtItem.column_id]?.includes(x?.trim);
                  case 'lt':
                    return (rep?.people[filtItem.column_id] ?? "") < x?.trim();
                  case 'gt':
                    return (rep?.people[filtItem.column_id] ?? "") > x?.trim();
                  case 'lte':
                    return (rep?.people[filtItem.column_id] ?? "") < x?.trim();
                  case 'gte':
                    return (rep?.people[filtItem.column_id] ?? "") > x?.trim();
                  default:
                    return true;
                }
              }) !== -1;
            }
          );
        }
        if (sections?.length > 0) {
          selectReps = selectReps.filter((p: any) => {
            let peopleSections = [];
            if(typeof p?.people?.sections === "string"){
              peopleSections = JSON.parse(p?.people?.sections);
            }
            return peopleSections.filter((q: any) => {
              return sections.findIndex((r: any) => r.value === q.subject) !== -1;
            })?.length > 0;
          })
        }
        const temp: any = { ...p };
        temp.responses = selectReps;
        if (selectReps.length > 0) {
          questions.push(temp);
        }
      })
    }

    questions.map((p: any) => {
      const selectReps: any[] = p.responses;
      legends.map(legend => {
        // eslint-disable-next-line array-callback-return
        legend?.contents?.map((content: any) => {
          content.sum += selectReps.filter(
            repContent => repContent.content?.toLowerCase() === content?.name?.toLowerCase()
          ).length;
        })
      })
    })

    function toJson(element:any){
      let result:any[] = [];
      if(Array.isArray(element)){
        return element;
      }
      try {
        if(element!=null && element!=undefined){
          result = JSON.parse(element);
        }
      } catch (error) {
        
      }
      return result;
    }

    const result = { questions, statics: items, legends };

    postMessage(result);
  };
};