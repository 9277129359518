import * as React from 'react';
import GeocoderControl from './geocoder-control';
import Map, {NavigationControl } from 'react-map-gl';
import { Markers } from './mapbox';
import { MAP_TOKEN } from '../../services/config';

function GeodecorLayout({ center }) {
    const [mapViewport, setMapViewport] = React.useState({
      height: "100%",
      width: "100%",
      longitude: parseFloat(center?.longitude ?? 0),
      latitude: parseFloat(center?.latitude ?? 0),
      zoom: 5
   });

    return (
      <Map
        initialViewState={mapViewport}
        mapStyle={"mapbox://styles/mapbox/navigation-day-v1"}
        style={{ height: 450,width: "100%",}}
        mapboxAccessToken={MAP_TOKEN}
        onMove={(nextViewport)=>setMapViewport(nextViewport)}
      >
        <GeocoderControl mapboxAccessToken={MAP_TOKEN} position="top-left" />
        <NavigationControl />
        <Markers mapboxAccessToken={MAP_TOKEN} />
      </Map>
    );
}

export default GeodecorLayout;