import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  profil: any;
  current: any;
  list: any[];
  all:any[];
}

const initialState: userData = {
  profil: {},
  current: {},
  list: [],
  all:[],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setListUser: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setListAllUser: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const {
  setProfil,
  setCurrent,
  setListUser,
  setListAllUser,
} = userSlice.actions;
export default userSlice.reducer;

