import { Skeleton, Spin } from "antd";
import React from "react";

export function Loader() {
  return (
    <div>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );
}

export function LoaderMapBox({absolute=false}){
  return (
    <div className="w-mpbox-loader" 
        style={absolute?{position:"absolute",width:"100%",zIndex:1,background:"#fffa"}:{}}
    >
      <Spin tip="Patientez s'il vous plaît le chargement de la carte !" spinning size="large" />
    </div>
  );
}

export function LoaderWQuiz({absolute=false}){
  return (
    <div 
      className="w-mpbox-loader"
      style={absolute?{position:"absolute",width:"100%",height:"100%",zIndex:1,background:"#fffa"}:{}}
    >
      <Spin tip="Patientez s'il vous plaît le chargement de questionnaires !" spinning size="large" />
    </div>
  );
}

export function SkeletonPie(){
  return (
    <div className="w-mpbox-loader">
      <Skeleton.Avatar active size={"large"} shape={"circle"} style={{width:"300px",height:"300px"}} />
    </div>
  );
}

export function SkeletonBarHorizon(){
  return (
    <div className="w-mpbox-loader">
      <Skeleton active paragraph />
    </div>
  );
}

export function SkeletonImage(){
  return (
    <div className="w-mpbox-loader">
      <Skeleton.Image active style={{width:"300px",height:"300px"}} />
    </div>
  );
}