import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SectionData {
  profil: any;
  current: any;
  list: any[];
  all:any[];
}

const initialState: SectionData = {
  profil: {},
  current: {},
  list: [],
  all:[],
};

export const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setListSection: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setListAllSection: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const {
  setProfil,
  setCurrent,
  setListSection,
  setListAllSection,
} = sectionSlice.actions;
export default sectionSlice.reducer;

