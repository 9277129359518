import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Layout } from 'antd';
import chartHelper from '../../utils/helpers/chartHelper';

export default function App({
    title="",
    data=[{}],
    type="bar",
  }) {

  const options = {
        chart: {
          width: "100%",
          height:"100%",
          type: type,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
          categories: chartHelper.getLabels(data, "type"),
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }
        
        },

      };

 return <Layout.Content style={{height:"400px", width:"100%"}}>
    <ReactApexChart options={options} series={[{name: 'Value',data:chartHelper.getSeries(data,"type")}]} type={type} width={"100%"} height={"100%"} />
 </Layout.Content>
}