import { filter, isEmpty, isNumber } from "lodash";
import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Navbar } from "../../components/navigation/me/navbar";
import { routes } from "../../routes";

import { setAppTitle } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { NotFoundScreen } from "../404";
import { FallbackScreen } from "../fallback";
import { useDispatch, useSelector } from "react-redux";
import dataHelper from "../../utils/helpers/dataHelper";
import { SplashScreen } from "../splash";
import formatHelper from "../../utils/helpers/formatHelper";
import { setTreatmentColumns, setTreatmentData, setTreatmentList, setTreatmentLoading } from "../../redux/treatmentReducer";
import exploreWorker from "../../services/workers/exploreWorker";
import IWorker from "../../services/workers/IWorker";
import tableHelper from "../../utils/helpers/tableHelper";
import { setDashboardIndex, setPercent } from "../../redux/dashboardReducer";

export function MeLayout() {


  const dispatch = useDispatch();
  const userAdmin = userHelper.getData();
  const current = userHelper.getSurveyData();
  const routerLocation = useLocation();
  const [loading, setLoading] = React.useState(true);
  const quizzes = useSelector(state => state.quiz.list);
  const sections = useSelector(state => state.section.list);
  const peoples = useSelector((state) => state.people.list);
  const users = useSelector((state) => state.user.list);
  const responses = useSelector((state) => state.reponse.all);
  const explores = useSelector((state) => state.treatment.list);
  const [isPending, startTransition] = React.useTransition();
  const exploring = useSelector((state) => state.treatment.loading);
  const loaded = useSelector((state) => state.dashboard.loaded);
  const dashboardIndex = useSelector((state) => state.dashboard.index);


  let instance = null;

  function getMessages() {
    instance.onmessage = (message) => {
      const data = message.data ?? {};
      const treats = data?.treats ?? [];
      const columns = data?.columns ?? [];
      if (treats?.length > 0) {
        startTransition(() => {
          dispatch(setTreatmentList(treats))
          dispatch(setTreatmentColumns(columns));
          const excelData = tableHelper.createJsonToExport(treats, columns);
          dispatch(setTreatmentData(excelData));
          dispatch(setTreatmentLoading(false));
        })
        instance.terminate();
      }
    }
  }

  React.useEffect(() => {
    if (users.length > 0 && responses.length > 0 && peoples.length > 0 && quizzes.length>0) {
      if (explores.length === 0) {
        initExplore();
      } else {
        dispatch(setTreatmentLoading(false));
      }
    }
  }, [users, peoples, responses,quizzes])

  async function initExplore() {
    dispatch(setTreatmentLoading(true));
    instance = new IWorker(exploreWorker);
    getMessages();
    instance.postMessage({
      peoples, responses, current, users, quizzes, sections
    },
    );
  }



  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);

  React.useEffect(() => {
    setLoading(true);
    initData();
  }, [])

  async function init() {
    dispatch( setDashboardIndex(0) );
    dispatch( setPercent(0) );
    await dataHelper.initData(dispatch,dashboardIndex);
    setLoading(false);
  }

  const initData = React.useCallback(() => {
    init();
  }, [])

  if (isEmpty(userAdmin)) {
    return (
      <Navigate
        to="/"
        replace
        state={{ message: "Vous devez vous connecter avant tout" }}
      />
    );
  }

  if (isEmpty(current)) {
    return (
      <Navigate
        to="/home/"
        replace
        state={{ message: "Vous devez choisir une enquête" }}
      />
    );
  }



  setAppTitle("Survey");

  return (
    <React.Fragment>

      <Navbar key="nav-admin">
        <Suspense fallback={<FallbackScreen />}>
          <Routes>
            {routes.filter(
              (p) =>
                p.layout === "/me" &&
                userHelper.isVisible(p.visibility, userAdmin, p.subscribeable, true)
            ).map((p, index) => {
              return (
                <Route
                  path={`${p.path}`}
                  element={<p.component />}
                  key={`${p.name}${index}`}
                />
              );
            })}
            <Route path={`*`} element={<NotFoundScreen />} key={`notfound`} />
          </Routes>
        </Suspense>
      </Navbar>
      <SplashScreen 
        loading={loading} exploring={exploring || isPending} 
        loaded={!loaded} setLoading={setLoading}
      />
    </React.Fragment>
  );
}