import { AnyAction, Dispatch } from "redux";
import { setBasicStats, setCoordinates, setLegends, setPercent, setQuestions, setDashboardFilters, setDashboardIndex } from "../../redux/dashboardReducer";
import peopleService from "../../services/peopleService";
import reponseService from "../../services/reponseService";
import userHelper from "./userHelper";
import { filter, isEmpty, isNumber, toLower } from "lodash";
import { setListAllReponse, setListReponse } from "../../redux/reponseReducer";
import quizService from "../../services/quizService";
import { setListAllQuiz, setListQuiz } from "../../redux/quizReducer";
import provinceService from "../../services/provinceService";
import { setListAllProvince, setListProvince } from "../../redux/provinceReducer";
import { setListAllPeople, setListPeople } from "../../redux/peopleReducer";
import legendeService from "../../services/legendeService";
import { setListAllLegende, setListLegende } from "../../redux/legendeReducer";
import formatHelper from "./formatHelper";
import userService from "../../services/userService";
import { setListAllUser, setListUser } from "../../redux/userReducer";
import { filterType } from "../types";
import researchService from "../../services/researchService";
import { setListAllResearch, setListResearch } from "../../redux/researchReducer";
import sectionService from "../../services/sectionService";
import { setListAllSection, setListSection } from "../../redux/sectionReducer";
import { setTreatmentList } from "../../redux/treatmentReducer";

class DataHelper {

    async initData(dispatch: Dispatch<AnyAction>, index?: number, filters?: filterType[]) {
        const current = userHelper.getSurveyData();
        const userAdmin = userHelper.getData();
        const max = 8;
        dispatch(setPercent(1));
        dispatch(setPercent(2));
        this.progress(0, max, dispatch);
        await this.getResponses(current.id, dispatch);
        await this.getUsers(userAdmin.enterprise_id, dispatch);
        const researchs = await this.getResearchs(dispatch);
        await this.getSection(dispatch);
        const peoples = await this.getPeople(current.id, "", dispatch);
        this.progress(1, max, dispatch);
        const provinces = await this.getProvince(dispatch);
        this.progress(2, max, dispatch);
        //this.getQuizBySurvey(current.id, dispatch);
        this.progress(3, max, dispatch);
        const quiz = await this.getQuiz(current.id, "", dispatch);
        this.progress(4, max, dispatch);
        const responses: any[] = [];
        this.progress(5, max, dispatch);
        this.progress(6, max, dispatch);
        const legends = await this.getLegends(dispatch);
        this.progress(7, max, dispatch);
        await this.getData(current?.id, dispatch, legends,
            provinces, responses, quiz, peoples, [], current, [], researchs
        );
        this.progress(8, max, dispatch, index);
    }


    private async getFiltres(id: string, dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await researchService.getBySurveyId(id).then(response => {
            items = researchService.getData(response);
            dispatch(setDashboardFilters(items));
        }).catch(() => { })
        return items;
    }

    public getFilters(type: string, items: filterType[]): filterType[] {
        let query = ``;
        const list: filterType[] = items.filter(p => p.type === type);
        /*list.map((p:filterType)=>{
            query+=`${p.key}=${p.value}&`;
        })*/
        return list;
    }

    private progress(step: number, max: number, dispatch: Dispatch<AnyAction>, index?: number) {
        let perc: number = (step / max)*100;
        if (perc > 100) {
            perc = 100;
        }
        dispatch(setPercent(perc));
    }

    private async getPeople(id: string, query: string, dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await peopleService.getBySurveyKey(id, query).then(response => {
            items = peopleService.getData(response);
            dispatch(setListPeople(items));
            dispatch(setListAllPeople(items));
        }).catch(() => { })
        return items;
    }

    private async getProvince(dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await provinceService.getAll().then(response => {
            items = provinceService.getData(response);
            dispatch(setListProvince(items));
            dispatch(setListAllProvince(items));
        }).catch(() => { })
        return items;
    }
    private async getSection(dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await sectionService.getAll().then(response => {
            items = sectionService.getData(response);
            dispatch(setListSection(items));
            dispatch(setListAllSection(items));
        }).catch(() => { })
        return items;
    }
    private async getQuiz(id: string, query: string, dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await quizService.getBySurveyKey(id, query).then(response => {
            items = quizService.getData(response);
            dispatch(setListQuiz(items));
            dispatch(setListAllQuiz(items));
        }).catch(() => { })
        return items;
    }

    private async getQuizBySurvey(id: string, dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await quizService.getByKey(`query/${id}`).then(response => {
            items = quizService.getData(response);
            dispatch(setListQuiz(items));
            dispatch(setListAllQuiz(items));
        }).catch(() => { })
        return items;
    }

    public async getResponses(id: string, dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await reponseService.getByKey(`query/${id}`).then(response => {
            items = reponseService.getData(response);
            dispatch(setListReponse(items));
            dispatch(setListAllReponse(items));
        }).catch(() => { })
        return items;
    }

    private async getLegends(dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await legendeService.getAll().then(response => {
            items = legendeService.getData(response);
            dispatch(setListLegende(items));
            dispatch(setListAllLegende(items));
        }).catch(() => { })
        return items;
    }

    private async getUsers(id: string, dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await userService.getByKey(`query/${id}`).then(response => {
            items = userService.getData(response);
            dispatch(setListUser(items));
            dispatch(setListAllUser(items));
        }).catch(() => { })
        return items;
    }


    private async getResearchs(dispatch: Dispatch<AnyAction>) {
        let items: any[] = [];
        await researchService.getAll().then(response => {
            items = researchService.getData(response);
            dispatch(setListResearch(items));
            dispatch(setListAllResearch(items));
        }).catch(() => { })
        return items;
    }

    public async getBySurveyAllData(
        dispatch: Dispatch<AnyAction>,
        responses: any[], peoples: any[],
        users: any[], current: any
    ) {
        const treats = formatHelper.getFlatingDataSurvey(peoples, responses, users, current);
        dispatch(setTreatmentList(treats));
    }

    public async getData(
        id: string, dispatch: Dispatch<AnyAction>, legends: any[],
        provinces: any[], responses: any[], quiz: any[], peoples: any[],
        users: any[], current: any, filters: filterType[], researchs: any[]
    ) {
        const legendItems: any[] = filter(legends, { "investigation_id": id });
        const listPeopleId: any[] = [];
        // let reponses:any[] = [];

        // quiz.map((q)=>{
        //     q?.responses?.map((rq:any)=>{
        //       const item = {
        // 		"content": rq?.content,
        // 		"id": rq?.id,
        // 		"is_image": rq?.is_image,
        // 		"photo": rq?.photo,
        //         "people_id": rq?.people?.id,
        //         "quiz_id": q?.id,
        //         "investigation_id":current?.id,
        //       }
        //        reponses.push(item);
        //     })
        // })

        //const treats = formatHelper.getFlatingDataSurvey(peoples, reponses, users, current);

        // const treats = formatHelper.getFlatingDataSurvey(
        //     peoples, responses, users, current);
        // legendItems.map((p:any)=>{
        //     p.contents?.map((x:any)=>{
        //         formatHelper.getTreatmentLengthByKey(treats,
        //           listPeopleId,p.column_id,p.table_id,x.name)
        //     })
        // })


        /*const peopleFilter:any[] = this.getFilters("map",filters);
        let peopleList:any[] = peoples;
        if(peopleFilter.length>0){
            peopleList = peoples.filter((p:any)=>peopleFilter.findIndex((x:filterType)=>x.value === p?.gender)!=-1);
        }*/
        const male = peoples.filter(p => toLower(p.gender) === "m").length;
        const feminine = peoples.filter(p => toLower(p.gender) === "f").length;
        const coordinates = peoples.map(
            (p: any) => ({
                longitude: isEmpty(p.longitude) ? 0.00 : p.longitude,
                latitude: isEmpty(p.latitude) ? 0.00 : p.latitude
            })
        )
        dispatch(setDashboardFilters(filters));
        dispatch(setLegends(legendItems));
        dispatch(setCoordinates(coordinates));
        dispatch(setBasicStats({ feminine, male, total: male + feminine }))
        dispatch(setQuestions(quiz));
        dispatch(setListAllResearch(researchs));
        //dispatch(setTreatmentList(treats));
    }

}

export default new DataHelper();