import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LegendeData {
  legende: any;
  list: any[];
  all: any[];
}

const initialState: LegendeData = {
  legende: {},
  list: [],
  all: []
};

export const legendeSlice = createSlice({
  name: "legende",
  initialState,
  reducers: {
    setListLegende: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setLegende: (state, action: PayloadAction<any>) => {
      state.legende = action.payload;
    },
    setListAllLegende: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    }
  },
});
export const {
  setListLegende,
  setLegende,
  setListAllLegende,
} = legendeSlice.actions;
export default legendeSlice.reducer;


