import React from "react";
import {Layout,Badge} from "antd";
import userHelper from "../../utils/helpers/userHelper";
import ManPicture from "../../assets/profile/homme.png";
import WomanPicture from "../../assets/profile/femme.png";
import { upperCase } from "lodash";
import dateHelper from "../../utils/helpers/dateHelper";


export function ProfilePage() {
const userAdmin = userHelper.getData();

  return (
    <>
      <Layout.Content>
        <Layout.Content className="container-profile">
           <Layout.Content className="wrapper-profile">
             <div className="w-picture">
              <img src={ManPicture} />
             </div>
             <div className="w-info">
               <h4 className="info-name">
                {userAdmin?.first_name} {userAdmin?.last_name}
               </h4>
               <div>
               <Badge.Ribbon text="Premium" color="gold">
                  <div className="badge-info">
                    <div className="info-date">Registered {dateHelper.getNow(userAdmin?.created_at)}</div>
                    <div className="info-enterprise">
                      {upperCase(userAdmin?.agent?.intitule)}
                    </div>
                  </div>
                </Badge.Ribbon>
               </div>
             </div>
           </Layout.Content>
        </Layout.Content>
        <Layout.Content>
          <Layout.Content className="quick-contact">
            <Layout.Content>
               <div className="info-perso-gender">
                 <div className="title">Genre</div>
                 <div className="val">{userAdmin?.gender}</div>
               </div>
               <div className="info-perso-gender">
                 <div className="title">Contact</div>
                 <div className="val"></div>
               </div>
               <div className="info-perso">
                 <div className="perso-title">Email : </div>
                 <div>{userAdmin?.email}</div>
               </div>
               <div className="info-perso">
                 <div className="perso-title">Tél : </div>
                 <div>{userAdmin?.phone}</div>
               </div>
               <div className="info-perso-gender">
                 <div className="title">Entreprise</div>
                 <div className="val"></div>
               </div>
               <div className="info-perso">
                 <div className="perso-title">sigle : </div>
                 <div>{upperCase(userAdmin?.agent?.sigle)}</div>
               </div>
               <div className="info-perso">
                 <div className="perso-title">Nom : </div>
                 <div>{upperCase(userAdmin?.agent?.intitule)}</div>
               </div>
            </Layout.Content>
          </Layout.Content>
        </Layout.Content>
      </Layout.Content>
    </>
  );
}