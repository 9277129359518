import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GeolibInputCoordinates } from "geolib/es/types";
import { basicStatType, filterType, staticFilter } from "../utils/types";


export interface dashboardData {
  coordinates: GeolibInputCoordinates[];
  basicStats: basicStatType;
  legends: any[];
  questions:any[];
  percent:number;
  filters:filterType[],
  statics:staticFilter[],
  loaded:boolean;
  index:number;
}

const initialState: dashboardData = {
  basicStats:{feminine:0,male:0,total:0},
  coordinates:[],
  legends:[],
  questions:[],
  percent:0,
  filters:[],
  statics:[],
  loaded:false,
  index:0,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setCoordinates: (state, action: PayloadAction<GeolibInputCoordinates[]>) => {
      state.coordinates = action.payload;
    },
    setBasicStats: (state, action: PayloadAction<basicStatType>) => {
      state.basicStats = action.payload;
    },
    setLegends: (state, action: PayloadAction<any[]>) => {
      state.legends = action.payload;
    },
    setQuestions: (state, action: PayloadAction<any[]>) => {
      state.questions = action.payload;
    },
    setPercent: (state, action: PayloadAction<number>) => {
      state.percent = action.payload;
    },
    setDashboardFilters: (state, action: PayloadAction<filterType[]>) => {
      state.filters = action.payload;
    },
    setDashboardStatics: (state, action: PayloadAction<staticFilter[]>) => {
      state.statics = action.payload;
    },
    setDashboardLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setDashboardIndex: (state, action: PayloadAction<number>) => {
      state.index = action.payload;
    },
  },
});
export const {
  setBasicStats,setCoordinates,setLegends,setQuestions,setPercent,setDashboardFilters,
  setDashboardStatics,setDashboardLoaded,setDashboardIndex
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

