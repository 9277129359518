import { Button, Drawer, Layout } from "antd";
import React from "react";
import PropTypes from "prop-types";
import LeftMenu from "./leftMenu";
import logo from "../../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { FooterHome } from "../../../screens/root/footer";
import { SidebarMenu } from "./sidebarMenu";

function Navbar(props) {
  const [visible, setVisible] = React.useState(false);
  const location = useLocation();

  function handleClose() {
    setVisible(false);
  }

  function handleOpen() {
    setVisible(true);
  }

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("admin-navbar").classList.add("yc-navbar-shadow");
    } else {
      document
        .getElementById("admin-navbar")
        .classList.remove("yc-navbar-shadow");
    }
  }

  window.onscroll = function () {
    scrollFunction();
  };

  return (
    <main>
      <nav className="yc-menu yc-menu-fixed" id="admin-navbar">
        <div className="yc-logo">
          <a href="/">
            <img src={logo} />
          </a>
        </div>
        <div className="yc-menu-con">

          <div className="yc-left-menu">
            <LeftMenu mode="horinzotal" current={location.pathname} />
          </div>
         
          <div className="yc-right-menu">

          </div>

          {/*<div className="yc-navigator">
            <ToolNavbar />
          </div>*/}
          
          <Button className="yc-navigator" onClick={handleOpen}>
            <span className="yc-navigator-btn"></span>
          </Button>
          
          <Drawer
            title={"WiiCollect"}
            placement="right"
            closable={true}
            onClose={handleClose}
            open={visible}
            bodyStyle={{ display: "flex", flexDirection: "column" }}
            contentWrapperStyle={{ maxWidth: 330 }}
          >
            <SidebarMenu setVisible={setVisible} current={location.pathname} />
          </Drawer>
        </div>
      </nav>

      <Layout>
        <Layout.Content className="wii-home-main">
          <Layout.Content style={{ width:"100%",height:"100%"}}>
            {props.children}
          </Layout.Content>
          <FooterHome />
        </Layout.Content>
      </Layout>

    </main>
  );
}

Navbar.propTypes = {
  children: PropTypes.element,
};

export { Navbar };

/**
 * 
 * <Affix offsetTop={65} style={{backgroundColor:"#fff"}}>
          <Layout.Sider 
           style={{backgroundColor:"#fff"}}
           className={`iag-sider ${!userHelper.visibilityFilter(location.pathname) ? "iag-sider-visible" : ""}`}
           width={300}
          >
            <FilterView />
          </Layout.Sider>
        </Affix>
 * 
 */
