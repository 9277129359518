import React from 'react'
import PropTypes from "prop-types";
import { Column, Bar} from '@ant-design/plots';
import { Pie } from '@ant-design/plots';

export default function App({
    title="",
    data=[{}],
    plein="miCircle",
  }) {

const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        //innerRadius: 0.1,
        startAngle: Math.PI,
        endAngle: Math.PI * 1.5,
        label: {
          type: 'inner',
          offset: '-25%',
          content: '{value}',
          style: {
            textAlign: 'center',
            fontSize: 14,
          },
        },
        interactions: [
          {
            type: 'element-active',
          },
        ],
        pieStyle: {
          lineWidth: 0,
        },
      };
      return <Pie {...config} />;
}

App.propTypes = {
  title: PropTypes.string,
  data:PropTypes.array,
  plein:PropTypes.string,
};  