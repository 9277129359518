import React from "react";
import { useFormik } from "formik";
import {setAppTitle } from "../utils/helpers/title";
import * as yup from "yup";
import userService from "../services/userService";
import { useDispatch } from "react-redux";
import userHelper from "../utils/helpers/userHelper";

import {Layout,Button,Row,Typography,Form,Input,Switch,notification, Image} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import logo from "../assets/images/logo.png";
import {Grid} from "@mui/material";
import { FooterHome } from "./root/footer";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const { Title } = Typography;

export const phoneRegex = /^[+][(]{0,1}[0-9]{1,4}[)]{0,1}([-\s\./0-9]{6,10})$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export function SignInPage(){

const regex = /^[+][(]{0,1}[0-9]{1,4}[)]{0,1}([-\s\./0-9]{6,10})|^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const [loading, setLoading] = React.useState(false);
const dispatch = useDispatch();

const formik = useFormik({
  initialValues: { phone: "",password:""},
  onSubmit: handleConnexion,
  validationSchema: yup.object().shape({
    phone: yup.string()
      .required("Le champ est requis")
      .matches(regex, { message: "Ecrivez correctement votre numéro ou email." }),
    password: yup.string()
    .required("Ce champs est requis"),
 })
});

async function handleConnexion() {
  setLoading(true);
  const value = formik.values;
  if (phoneRegex.test(value.phone)) {
    const data = {"phone":value.phone,"password":value.password};
    await userService
      .login(data)
      .then((response)=>{
        
        userHelper.setToken(response.data.token);
        userHelper.setData(response.data, dispatch);
        window.location.href ="/home";
      })
      .catch((reason)=>{
        notification.error({
          message: "Connexion",
          description: `La connexion a échoué: veuillez vérifier votre numéro`,
        });
      });
  } 
  else if(emailRegex.test(value.phone)){
     const data = {"email":value.phone,"password":value.password};
     await userService
       .login(data)
       .then((response)=>{
        userHelper.setToken(response.data.token);
        userHelper.setData(response.data, dispatch);
       })
       .catch((reason)=>{
         notification.error({
           message: "Connexion",
           description: `La connexion a échoué: veuillez vérifier votre email`,
         });
       });
  }
  setLoading(false);
}

setAppTitle("Connexion");

    return (
      <>
        {!userHelper.isConnected() && <Layout.Content>
           <Grid columnSpacing={{ xs: 1, sm: 1, md: 0 }} justifyContent={"center"} rowSpacing={0.5} container>
              <Grid xs={12} sm={6} md={6} item>
                <Layout.Content className="iag-sign-left">
                   <Layout.Content className="iag-wrapper-sign">
                        <Row className="iag-image-sign">
                          <Image preview={false} src={logo} height={80} />
                        </Row>
                        <Title className="iag-title-sign">Sign In</Title>
                        <Form
                        layout="vertical"
                        className="row-col"
                        >
                        <Form.Item
                            className="username"
                            label="Email or Phone"
                            name="phone"
                            rules={[
                            {
                                required: true,
                                message: formik.errors['phone'],
                            },
                            ]}
                        >
                            <Input 
                            prefix={<UserOutlined className="site-form-item-icon" />} 
                            placeholder="example : +243000000000 or tmsoft@iag.com" 
                            name="phone"
                            value={formik.values['phone']}
                            onChange={formik.handleChange}

                            />
                        </Form.Item>

                        <Form.Item
                            className="username"
                            label="Password"
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: formik.errors['password'],
                            },
                            ]}
                        >
                            <Input.Password 
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password" 
                            name="password"
                            value={formik.values['password']}
                            onChange={formik.handleChange}
                            />
                        </Form.Item>

                        <Form.Item
                            name="remember"
                            className="aligin-center"
                            valuePropName="checked"
                        >
                          <Grid container>
                            <Grid item>
                              <Switch defaultChecked onChange={onChange} />
                            </Grid>
                            <Grid style={{margin:"0px 7px"}} item>Remember me</Grid>
                          </Grid>
                        </Form.Item>

                        <Form.Item>
                            <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                            loading={loading}
                            disabled={loading}
                            shape="round"
                            onClick={()=>formik.handleSubmit()}
                            >
                            SIGN IN
                            </Button>
                        </Form.Item>
                    </Form>
                   </Layout.Content>
                </Layout.Content>
                <FooterHome />
              </Grid>
              <Grid xs={12} sm={6} md={6} item>
                <Layout.Content className="iag-sign-right">
                  <div className="iag-image-wrapper">
                    <Image preview={false} src={logo} />
                  </div>
                </Layout.Content>
              </Grid>
          </Grid>
        </Layout.Content>}
      </>
    );
}

{/*<div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1">
                  <Link to="/">
                    {template}
                    <span> Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/">
                    {signin}
                    <span> Sign In</span>
                  </Link>
                </Menu.Item>
              </Menu>
              </div>*/}
/**
 * 
 * <Header>
            <div className="header-col header-brand">
              <img src={logo} alt="" height={"45px"} />
            </div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Title className="mb-15">Sign In</Title>
                <Form
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Email or Phone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: formik.errors['phone'],
                      },
                    ]}
                  >
                    <Input 
                       prefix={<UserOutlined className="site-form-item-icon" />} 
                       placeholder="example : +243000000000 or tmsoft@iag.com" 
                       name="phone"
                       value={formik.values['phone']}
                       onChange={formik.handleChange}

                    />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: formik.errors['password'],
                      },
                    ]}
                  >
                     <Input.Password 
                       prefix={<LockOutlined className="site-form-item-icon" />}
                       type="password" 
                       name="password"
                       value={formik.values['password']}
                       onChange={formik.handleChange}
                       />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked onChange={onChange} />
                    Remember me
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={loading}
                      disabled={loading}
                      shape="round"
                      onClick={()=>formik.handleSubmit()}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Content>
 */