import surveyService from "../surveyService";

export async function getSurveyQuery(id){
    return surveyService.getByKey(`query/${id}`).then((r)=>{
        return r.data;
    }).catch((reason)=>console.log("REAAASON"));
}

export async function getSurveyAll(){
    return surveyService.getAll().then((r)=>r.data);
}

/**
 *  return surveyService.getByKey(`query/${id}`).then((r)=>{
        console.log("SERVICE",r.data)
        return r.data;
    })
    .catch((reason)=>console.log("REAAASON",reason));
 */