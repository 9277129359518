import { isEmpty, startsWith, trim } from "lodash";

class PhoneHelper{

  vodaRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+24381") || startsWith(trim(phone),"081")) || 
    (startsWith(trim(phone),"+24382") || startsWith(trim(phone),"082")) || 
    (startsWith(trim(phone),"+24383") || startsWith(trim(phone),"083"));
  }

  orangeRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+24380") || startsWith(trim(phone),"080")) || 
    (startsWith(trim(phone),"+24389") || startsWith(trim(phone),"089")) || 
    (startsWith(trim(phone),"+24384") || startsWith(trim(phone),"084")) || 
    (startsWith(trim(phone),"+24385") || startsWith(trim(phone),"085"));
  }

  africellRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+24390") || startsWith(trim(phone),"090")) || 
    (startsWith(trim(phone),"+24391") || startsWith(trim(phone),"091"));
  }

  airtelRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+24399") || startsWith(trim(phone),"099")) || 
    startsWith(trim(phone),"+24397") || startsWith(trim(phone),"097");
  }

  otherRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return !(this.vodaRexp(phone) || this.orangeRexp(phone) || this.africellRexp(phone) ||
    this.airtelRexp(phone));
  }

  //Phone Number RCA
  acellRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+23670") || startsWith(trim(phone),"70"));
  }

  telecelRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+23675") || startsWith(trim(phone),"75"));
  }

  nationalinkRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+23677") || startsWith(trim(phone),"77"));
  }

  orangeRcaRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+23672") || startsWith(trim(phone),"72"))
    || (startsWith(trim(phone),"+23674") || startsWith(trim(phone),"74"));
  }

  socatelRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return (startsWith(trim(phone),"+23621") || startsWith(trim(phone),"21")) || 
    (startsWith(trim(phone),"+23622") || startsWith(trim(phone),"22"));
  }

  otherRcaRexp(phone:string):boolean{
    phone = !isEmpty(phone) ? phone : "";
    return !(this.acellRexp(phone) || this.telecelRexp(phone) || this.nationalinkRexp(phone) ||
    this.orangeRcaRexp(phone) || this.socatelRexp(phone)
    );
  }


  switchCasePhoneByTelecom(phone:string,key:string):boolean{
    switch (key) {
      case "voda":
        return this.vodaRexp(phone);
      case "orange":
        return this.orangeRexp(phone);
      case "afr":
        return this.africellRexp(phone);
      case "airtel":
        return this.airtelRexp(phone);
      case "other":
          return this.otherRexp(phone);
      default:
        return false;
    }
  }

  switchCasePhoneByRcaTelecom(phone:string,key:string):boolean{
    switch (key) {
      case "acell":
        return this.acellRexp(phone);
      case "orange":
        return this.orangeRcaRexp(phone);
      case "telecel":
        return this.telecelRexp(phone);
      case "nationlink":
        return this.nationalinkRexp(phone);
      case "socatel":
          return this.socatelRexp(phone);
      case "other":
          return this.otherRcaRexp(phone);
      default:
        return false;
    }
  }
}
export default new PhoneHelper();