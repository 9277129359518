import { lazy } from "react";

import { SignInPage } from "./screens/signin";
import { SurveyPage } from "./screens/home/survey";
import { ProfilePage } from "./screens/home/profile";
import { DashboardPage } from "./screens/me/dashboard";
import { AnalyticsPage } from "./screens/me/analytics";
import { ExplorePage } from "./screens/me/explore";

/*const DashboardPage = lazy(() =>
  import("./screens/me/dashboard").then(({ DashboardPage }) => ({
    default: DashboardPage,
  }))
);*/

/*const AnalyticsPage = lazy(() =>
  import("./screens/me/analytics").then(({ AnalyticsPage }) => ({
    default: AnalyticsPage,
  }))
);*/

/*const ExplorePage = lazy(() =>
  import("./screens/me/explore").then(({ExplorePage }) => ({
    default: ExplorePage,
  }))
);*/



declare type layoutType = "/admin" | "/home" | "/me" | "/";
export declare type userType = "simple" | "admin" | "super admin";

export interface routeInterface {
  name: string;
  label: string;
  path: string;
  layout: layoutType;
  isMenu: boolean;
  component: any;
  icon: any | null;
  iconTransition?: any | null;
  parent?: string;
  visibility: userType[];
}

const routes: routeInterface[] = [
  
  {
    component: SignInPage,
    icon: null,
    isMenu: false,
    label: "Connexion",
    layout: "/home",
    path: "/login",
    name: "login",
    iconTransition: null,
    visibility: ["simple", "admin", "super admin"]
  },
   
  {
    component: SurveyPage,
    icon: null,
    isMenu: true,
    label: "Survey",
    layout: "/home",
    path: "/",
    name: "survey",
    iconTransition: null,
    visibility: ["simple", "admin", "super admin"]
  },
  {
    component: ProfilePage,
    icon: null,
    isMenu: true,
    label: "Profile",
    layout: "/home",
    path: "/profile",
    name: "survey",
    iconTransition: null,
    visibility: ["simple", "admin", "super admin"]
  },
  {
    component: DashboardPage,
    icon: null,
    isMenu: true,
    label: "Dashboard",
    layout: "/me",
    path: "/",
    name: "dashboard",
    iconTransition: null,
    visibility: ["simple", "admin", "super admin"]
  },
  {
    component: AnalyticsPage,
    icon: null,
    isMenu: true,
    label: "Analytics",
    layout: "/me",
    path: "/analytics",
    name: "analytics",
    iconTransition: null,
    visibility: ["simple", "admin", "super admin"]
  },
 
  {
    component: ExplorePage,
    icon: null,
    isMenu: true,
    label: "Explore",
    layout: "/me",
    path: "/explore",
    name: "explore",
    iconTransition: null,
    visibility: ["simple", "admin", "super admin"]
  }
];

export { routes };