import { Layout } from "antd";
import React from "react";

export function FooterHome() {
  return (
    <Layout.Footer style={{ background: "#fff", width:"100%" }}>
      <div style={{ textAlign: "center" }}>
        {`Copyright`}{" "}
        <a
          href="https://impactanalytics-group.com/"
          title=""
          target="_target"
        >
          {`WiiCollect`}
        </a>
        {` ©`}{new Date().getFullYear()}
        {" by IAG"}
      </div>
    </Layout.Footer>
  );
}
