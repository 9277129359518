import React from 'react';
//import { ExcelExportColumn, ExcelExportColumnGroup} from '@progress/kendo-react-excel-export';
import { assign, remove, toPlainObject, toString } from 'lodash';
import tableHelper from './tableHelper';

/*
export function CustomColumnExport(props){
  props.data.map((d,k)=>{
        return <ExcelExportColumn 
          field={d.key}
          title={d.title}
        />
    }
  )
}
*/

var columns = [
  {
    title: "ID",
    key: "id",
    dataIndex:'id',
    fixed:'left',
    width:85,
  },
  {
    title: "FICHE",
    key: "fiche",
    dataIndex:'fiche',
    fixed: null,
    width:170,
  },
  {
    title: "SEXE",
    key: "gender",
    dataIndex:'gender',
    fixed: null,
    width:105,
  },
  {
    title: "PHONE",
    key: "phone",
    dataIndex:'phone',
    fixed: null,
    width:170,
  },
  {
    title: "PROVINCE",
    key: "province",
    dataIndex:'province',
    fixed: null,
    width:200,
  },
  {
    title: "COMMUNE",
    key: "common",
    dataIndex:'common',
    fixed: null,
    width:200,
  },
  {
    title: "QUARTIER",
    key: "quarter",
    dataIndex:'quarter',
    fixed: null,
    width:200,
  },
  {
    title: "PREFECTURE | CHEF-LIEU",
    key: "prefecture",
    dataIndex:'prefecture',
    fixed: null,
    width:230,
  },
  {
    title: "SOUS PREFECTURE",
    key: "sub_prefecture",
    dataIndex:'sub_prefecture',
    fixed: null,
    width:230,
  },
  {
    title: "OBJET ENQUETE",
    key: "survey_object_name",
    dataIndex:'survey_object_name',
    fixed: null,
    width:250,
  },
  {
    title:"PHOTO",
    key: "photo",
    dataIndex:'photo',
    fixed: null,
    width:825,
  },
  {
    title: "LATITUDE & LONGITUDE",
    key: "position",
    dataIndex:'position',
    fixed: null,
    width:230,
  },
  {
    title: "DATE SOUSMISSION",
    key: "created_at",
    dataIndex:'created_at',
    fixed: null,
    width:200,
  },
  {
    title: "ENQUETEUR",
    key: "user",
    dataIndex:'user',
    fixed: null,
    width:200,
  }
  
];


export function getColumnByPeople(sections,quizzes){
  var quiz = getColumQuiz(quizzes);
  var secs = getColumSection(sections);
  
  remove(columns,(c)=> tableHelper.checkByKey(c.key));

  for(var i=0;i<secs.length;i++){
    remove(columns,(c)=> c.key == secs[i].key);
    columns.push(secs[i]);
  }

  for(var i=0;i<quiz.length;i++){
    remove(columns,(c)=>c.key==quiz[i].key);
    columns.push(quiz[i]);
  }

  columns.push({
    title: "ACTION",
    fixed: 'right',
    width: 105,
    key: "action",
    dataIndex:"action",
    align:"center",
  });

  return columns;
 }

 export function getColumQuiz(quizzes){
  var qz = quizzes.map((q)=>(
    {title:q.sigle+"  "+q.description,key:"q_"+toString(q.id),width:q.component =="image" ? 825 : 400,dataIndex:"q_"+toString(q.id),fixed:null}
  ))
  return qz;
 }

 export function getColumSection(sections){
  var secs = sections.map((q)=>(
    {title:q.title,key:"sec_"+toString(q.id),width:400,dataIndex:"sec_"+toString(q.id),fixed:null}
  ))
  return secs;
 }

 export function toObjectInsert(responses,data){
  const result = responses.reduce((d,v)=>({...d,["q_"+v.quiz_id] : v.is_image==0 ? v.content : v.photo,["r_"+v.quiz_id] : v.id}),{});
  assign(data,result);
  return data;
}

export function toObjectSectionsInResponse(responses,sections){
  const result = sections.reduce((d,v)=>({...d,["sec_"+v.id] : v.subject}),{});
  assign(responses,result);
  return responses;
}

export const debounce = (fn, delay) => {
  let timer;

  return function() {
    clearTimeout(timer);

    timer = setTimeout(() => fn.apply(this, arguments), delay);
  };
};

export function addEvent(columns,getColumnSearchProps,onEditedProps){
  return columns.map((v)=>({...v,...(v.dataIndex==="action" ? onEditedProps() : getColumnSearchProps(v.dataIndex))}));
}

