import { Spin, Typography } from "antd";
import React from "react";


export const MeStatTotal:React.FC<{
    value:number;loading?:boolean
}>=({value,loading})=>{

    return(
        <>
            <div className="row-sync">
             <Typography.Title level={4}>TOTAL </Typography.Title>
             {loading && <div className="total-gen"><Spin spinning size="small" /></div>}
             {!loading && (
                <div className="total-gen">
                    {/*filter(chartHelper.filterResponseByPeoples(peoples, listPeopleId,"id"),{"investigation_id":current.id}).length*/}
                    {value}
                </div>
             )}
            </div>
        </>
    )
}