import { Spin, Typography } from 'antd';
import React from 'react';


export const MeStatGenre:React.FC<{
    title:string;color?:"primary"|"secondary";loading?:boolean;
    value:number;
}>=({
    title,color,value,loading
})=>{
    
    return(
        <>
            <div className="row-sync">
                <Typography.Title level={5}>{title}</Typography.Title>
                {loading && <div className={`total ${color??""}`}><Spin spinning size="small" /></div>}
                {!loading &&  (
                <div className={`total ${color??""}`}>
                    {value}
                </div>
                )}
         </div>
        </>
    )
}