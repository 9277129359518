import React from "react";
import {Layout,Card,Button,Avatar, Spin} from "antd";
import userHelper from "../../utils/helpers/userHelper";
import dateHelper from "../../utils/helpers/dateHelper";
import {upperCase} from "lodash";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSurveyQuery } from "../../services/middleware/surveyMiddle";
import { getUserQuery } from "../../services/middleware/userMiddle";


export function SurveyPage() {
const userAdmin = userHelper.getData();

const {isLoading:isSurveyLoad, data:listSurvey,isFetching:isRun} = useQuery(['surveys'],()=> getSurveyQuery(userAdmin.enterprise_id));
const {isLoading:isUserLoad, data:listUser} = useQuery(['users'],()=> getUserQuery(userAdmin.enterprise_id));

const surveys = listSurvey || [];
const users = listUser || [];

const [item, setItem] = React.useState({});

const navigation = useNavigate();

function handleView(survey){
  userHelper.setSurveyData(survey);
  window.location.href = "/me";
}

  return (
    <>
      <Layout.Content className="wii-home-survey-container">
       <h1 className="wii-home-survey-title">Mes enquêtes</h1>
       {(isSurveyLoad || isUserLoad) && <Spin spinning />}
       {(!isSurveyLoad && !isUserLoad) && <Layout.Content className="wii-home-survey-grid">
        {surveys.map((survey)=>{
          return <React.Fragment key={survey.id}>
             <Card className="wii-home-survey-card">
               <div className="wii-home-survey-card-top">
                  <div className="wii-home-survey-card-members">
                    <div><h5>Membres</h5></div>
                    <div>
                      <Avatar.Group className="avatar-chips" maxCount={4}>
                      {users.filter((user)=>user.enterprise_id==survey.enterprise_id)
                        .map((u)=>{
                          return (<Avatar size={"small"} key={u.id}>{
                          upperCase(u.first_name.trim().substr(0,1)+""+u.last_name.trim().substr(0,1))
                          }</Avatar>);
                        })
                        }
                       </Avatar.Group>
                    </div>
                  </div>
                  <div className="wrapper-date">
                    <div></div>
                    <div>{dateHelper.toDate(survey.created_at)}</div>
                  </div>
               </div>
               <div className="wii-home-survey-card-body">
                 <div>
                  <h5>{survey.sigle}</h5>
                  <p>{survey.description}</p>
                 </div>
                 <div>
                   <Button
                    onClick={()=>handleView(survey)}
                    >
                      Voir
                   </Button>
                 </div>
               </div>
             </Card>
          </React.Fragment>
        })}
       </Layout.Content>}
      </Layout.Content>
    </>
  );
}