import React from "react";
import { Progress, Space, Spin} from "antd";
import { isEmpty } from "lodash";
import userHelper from "../utils/helpers/userHelper";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface splashInterface {
  loading: boolean;
  setLoading: any;
  exploring?:boolean;
  loaded?:boolean;
}

export function SplashScreen(props: splashInterface) {
  const { loading, setLoading,exploring,loaded } = props;
  const user = userHelper.getData();
  const current = userHelper.getSurveyData();
  const percent = useSelector((state:RootState)=>state.dashboard.percent);
  

  React.useEffect(() => {
    if (loading) {
      window.document.body.style.height = "100vh";
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.height = "auto";
      window.document.body.style.overflow = "auto";
    }
  }, [loading,exploring,loaded]);

  return (
    <React.Fragment>
      {(loading) && (
        <div className='iag-splash'>
          <img src={require("../assets/images/logo.png")} alt="" />
          <div className='loading'>
            <Space size="middle">
                <Spin size="large" />
            </Space>
          </div>  
          <p>Please wait...</p>
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:300,margin:"0 auto"}}>
          {(<Progress 
            steps={26} showInfo={false} percent={(percent===100 && loading)?0:percent} status={percent>10?"active":"normal"}
          />)}
          </div>
          {(!loading && (exploring || loaded)) && (<p>{`Préparation de l'espace de travail`}</p>)}
        </div>
      )}
    </React.Fragment>
  );
}
