import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ResearchData {
  research: any;
  list: any[];
  all: any[];
}

const initialState: ResearchData = {
  research: {},
  list: [],
  all: []
};

export const researchSlice = createSlice({
  name: "research",
  initialState,
  reducers: {
    setListResearch: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setResearch: (state, action: PayloadAction<any>) => {
      state.research = action.payload;
    },
    setListAllResearch: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    }
  },
});
export const {
  setListResearch,
  setResearch,
  setListAllResearch,
} = researchSlice.actions;
export default researchSlice.reducer;


